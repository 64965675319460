import React, { Children, Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import {
  ABOUT_US,
  CONTACT_US,
  FEATURES,
  HOME,
  LEAGL,
} from "../constants/routePaths";
import Faq from "../views/faq/Faq";
import Pricing from "../views/pricing/Pricing";
import PrivateRoute from "./PrivateRoute";
import Demo from "../views/demo/Demo";
const Header = React.lazy(() => import("../components/header/Header"));
const Footer = React.lazy(() => import("../components/footer/Footer"));
const Home = React.lazy(() => import("../views/home/Home"));
const About = React.lazy(() => import("../views/about/About"));
const Contact = React.lazy(() => import("../views/contact/Contact"));
const Features = React.lazy(() => import("../views/features/Features"));

const loadingMarkup = <div className="preloader"></div>;

const Routes = () => {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={loadingMarkup}>
          <Switch>
            <Route exact path="*" component={Header} />
          </Switch>
          <Footer />
        </Suspense>
      </BrowserRouter>
    </>
  );
};

export default Routes;
