import { useEffect, useState } from "react";
import Spinner from "../../components/spinner/Spinner";
import "../content.css";

function VideoProduction() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="main__content">
      <h1 style={{ textAlign: "center" }}>VIDEO PRODUCTION</h1>
    </div>
  );
}

export default VideoProduction;
