import "../content.css";

import React, { useEffect, useState } from "react";
import pattern3 from "../../images/background/pattern-3.png";
import pattern4 from "../../images/background/pattern-4.png";
import pattern5 from "../../images/background/pattern-5.png";
import pattern6 from "../../images/background/pattern-6.png";
import benefit1 from "../../images/resource/benefit-1.jpg";
import benefit2 from "../../images/resource/benefit-2.png";
import benefit3 from "../../images/resource/benefit-3.jpg";
import cert from "../../images/resource/cert.png";
import cert1 from "../../images/resource/cert1.png";
import "./payperclick.css";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Spinner from "../../components/spinner/Spinner";
const newLOGO = "new-logo-underline.png";

function PayPerClick() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="main__content">
      {/* Benefit Section */}
      <section
        className="benefit-section"
        style={{
          padding: 0,
        }}
      >
        <div
          className="background-layer-one"
          style={{ backgroundImage: `url(${pattern5})` }}
        />
        <div
          className="background-layer-two"
          style={{ backgroundImage: `url(${pattern6})` }}
        />
        <div className="auto-container">
          <div className="row clearfix">
            {/* Images Column */}
            <div className="images-column col-lg-7 col-md-12 col-sm-12">
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                }}
                className="inner-column"
              >
                <img
                  width="95%"
                  src={
                    "https://image.freepik.com/free-psd/laptop-iphone-digital-marketing-background_23-2148346689.jpg"
                  }
                />
                {/* <div
                  className="pattern-layer"
                  style={{ backgroundImage: `url(${pattern4})` }}
                />
                <div
                  className="pattern-layer-two"
                  style={{ backgroundImage: `url(${pattern3})` }}
                />
                <div className="color-layer" />
                <div className="image">
                  <img
                    src={
                      "https://image.freepik.com/free-psd/laptop-iphone-digital-marketing-background_23-2148346689.jpg"
                    }
                    alt
                  />
                </div>
                <div className="image-two">
                  <img
                    width="354px"
                    height="337px"
                    src={
                      "https://media-assets-02.thedrum.com/cache/images/thedrum-prod/s3-news-tmp-213062-hallam_digitalmarketing1--default--1280.jpg"
                    }
                    alt
                  />
                </div>
                <div className="image-three">
                  <img width="354px" height="337px" src={benefit2} alt />
                </div> */}
              </div>
            </div>
            {/* Content Column */}
            <div className="content-column col-lg-5 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="sec-title">
                  {/* <div className="title">Learn anything</div> */}
                  <h2 style={{ marginLeft: "10px" }}>Pay per click</h2>
                </div>
                <ul className="list-style-one">
                  {/* <li><span className="icon flaticon-double-check" /><h3> {t('about')}: </h3> <strong style={{color:"#9e7d18"}}> TM7 SERVICES INC</strong><p>{t('TM7_description')}</p></li>
            <div className="btn-box">
            <a href="https://www.tm7.tech/"
                        target="_blank" className="theme-btn btn-style-two"><span className="txt">{t('find_out_button')} TM7 SERVICES INC</span></a>
          </div> */}
                  <li
                    style={{
                      padding: "20px 60px 0px 10px",
                    }}
                  >
                    <strong style={{ color: "#9e7d18" }}>
                      Pay per click (PPC)
                    </strong>
                    <p>
                      Pay per click is used to raise brand awareness, market
                      their services and goods, and create a targeted audience.
                      PPC advertisers get charged each time a user clicks on one
                      of their adverts. While PPC is most often linked with
                      Google SERPs and the Google Display Network, many other
                      digital platforms, including YouTube, Facebook, Pinterest,
                      and LinkedIn, So to take the benefits from it employ
                      pay-per-click advertisements today.
                      <br />
                      PPC marketing is centered on keywords, and PPC campaign
                      management systems such as Adwords provide value to
                      certain search terms and phrases. The value varies
                      according to the volume, difficulty, and competition of
                      the search keywords and phrases.
                      <br />
                    </p>
                  </li>
                  <div className="btn-box">
                    <a
                      href="#READMORE"
                      // target="_blank"
                      className="theme-btn btn-style-two"
                    >
                      <span className="txt">Read More...</span>
                    </a>
                  </div>
                </ul>
              </div>
            </div>
          </div>
          {/* Lower Text */}

          {/* --------------  UPDATE CONTENT START -------------------- */}
          <div id="READMORE">
            {/* Pay Per Click */}
            <section style={{ margin: "0 5%" }}>
              <h3 style={styles.title}>Pay Per Click-</h3>
              <div>
                <p style={styles.paraghrapText}>
                  Pay per click is used to raise brand awareness, market their
                  services and goods, and create a targeted audience. PPC
                  advertisers get charged each time a user clicks on one of
                  their adverts. While PPC is most often linked with Google
                  SERPs and the Google Display Network, many other digital
                  platforms, including YouTube, Facebook, Pinterest, and
                  LinkedIn, So to take the benefits from it employ pay-per-click
                  advertisements today.
                </p>

                <p style={styles.paraghrapText}>
                  PPC marketing is centered on keywords, and PPC campaign
                  management systems such as Adwords provide value to certain
                  search terms and phrases. The value varies according to the
                  volume, difficulty, and competition of the search keywords and
                  phrases.
                </p>

                <p style={styles.paraghrapText}>
                  PPC services that are highly competent and efficient will help
                  you to manage your PPC accounts more efficiently. They also
                  contribute to a higher return on investment (ROI). Unlike
                  traditional advertising methods, advertisers using
                  pay-per-click services are obligated to pay only when their ad
                  is clicked by a visitor, which is then routed to the
                  advertiser's website, rather than when their advertisement is
                  shown.
                </p>

                <p style={styles.paraghrapText}>
                  Another advantage of PPC is that its result is quantifiable. A
                  digital marketing firm like ours can assist you in precisely
                  understanding the complete wrap-up of PPC like the type of
                  clicks your PPC link receives, how many visitors visit your
                  site, and how many eventually purchase the product and become
                  your customers through PPC marketing.
                </p>

                <p style={styles.paraghrapText}>
                  Pay-per-click marketing may surely provide fast traffic to
                  enhance your present digital marketing efforts. However, to
                  gain its benefits, you must do it correctly. Here Draw Topic
                  Solution can assist you better! Get in touch with us to Enjoy
                  our classic PPC service.
                </p>
              </div>
            </section>
            {/* Need Of PPC */}
            <section style={{ margin: "0 5%" }}>
              <h3 style={styles.title}>Need Of PPC</h3>
              <div>
                <p style={styles.paraghrapText}>
                  PPC is one of the most important aspects of having effective
                  internet ads. It enables your company to prosper and emerge
                  above the competition. Although it may require considerable
                  time & expense, PPC will keep you relevant and competitive in
                  the long term.PPC ads might increase your consumers, phone
                  calls, or visitors to your website. After at least a month of
                  PPC advertising, you will be able to observe a difference in
                  your statistics & business that will give you a better vision
                  of where you should spend your money.
                </p>

                <p style={styles.paraghrapText}>
                  PPC is an indirect method of driving internet traffic to your
                  website — it is paid branding in which you pay for purchase
                  visits. However, because the PPC marketing management approach
                  is so adaptable, it has been considered one of the most
                  cost-efficient ways to generate leads and visitors online.
                </p>

                <p style={styles.paraghrapText}>
                  According to a Google study, for the money that companies
                  spend on paid marketing, they may earn more in return. PPC
                  advertising, often becoming the leading cause of on-page
                  conversions. When compared to organic marketing tactics such
                  as SEO, PPC is at least 50% more successful in terms of
                  benefiting.
                </p>

                <p style={styles.paraghrapText}>
                  With the help of skilled PPC services like Draw Topic
                  Solution, you can even start it with a lesser budget or
                  package, and after observing and monitoring reactions of PPC
                  ads over your business. You may increase the budget for paid
                  marketing on an ongoing basis if you find it impressive.
                </p>

                <p style={styles.paraghrapText}>
                  PPC marketing gives a significant – and positive – influence
                  on the majority of businesses and brands. If you are not going
                  with PPC marketing, you're probably missing out on worthy
                  visitors and income.
                </p>
              </div>

              <div>
                <h3 style={styles.title}>
                  Here are some of the most notable advantages of adopting PPC
                  marketing
                </h3>
                <div>
                  <ul className="importance_of_ppc-ul">
                    <li>Paid search traffic generation.</li>
                    <li>Lower your search engine marketing expenditures.</li>
                    <li>Take control of search pages.</li>
                    <li>Increase leads and/or sales</li>
                    <li>
                      Facilitate cooperation between your paid and natural
                      (organic) search initiatives.
                    </li>
                    <li>PPC enhances your revenue and goals.</li>
                    <li>PPC can boost SEO performance.</li>
                    <li>Data measurement and tracking.</li>
                    <li>You simply pay for clicks and get quick results.</li>
                    <li>
                      It works effectively in conjunction with other marketing
                      mediums.
                    </li>
                    <li>Extensive Target Possibilities</li>
                  </ul>
                </div>
              </div>
            </section>
            {/* WHY CHOOSE DRAW TOPIC: */}
            <section style={{ margin: "0 5%" }}>
              <h3 style={styles.title}>WHY CHOOSE DRAW TOPIC:</h3>
              <div>
                <p style={styles.paraghrapText}>
                  With the Draw Topic solution, your business is proven to be
                  useful for boosting results visibility, sales and revenue. We
                  evaluate keywords, market sectors, and business strategies to
                  establish a plan based on facts rather than assumptions.
                  Whether you require to establish a campaign from the beginning
                  or revamp an existing campaign, we can surely help.
                </p>

                <p style={styles.paraghrapText}>Our PPC services will:</p>
              </div>

              <div>
                <div>
                  <ul className="importance_of_ppc-ul">
                    <li>
                      Unlike other PPC marketing companies, We carefully
                      formulate our Paid advertising packages to maximize the
                      profits earned by businesses of all kinds.
                    </li>
                    <li>
                      You can trust us to send you detailed updates timely, That
                      will show how the actions and campaigns are working.
                      Besides this, we provide you accessibility to our client
                      dashboard, where you may track the progress of your
                      campaign.
                    </li>
                    <li>
                      Our PPC management specialists are certified Google
                      Adwords experts. Working with our professionals guarantees
                      the best PPC ad result.
                    </li>
                    <li>
                      Our team is dedicated to your success, we assist you in
                      meeting your marketing objectives by analyzing the
                      effectiveness of your recent campaigns.
                    </li>
                    <li>
                      Once you're a customer of Draw Topic Solution, We will
                      stably have a point of contact for running your Ad
                      campaigns, seeking any help, or answering any of your
                      questions.
                    </li>
                    <li>
                      Assist your company's website in obtaining fresh and
                      targeted leads through PPC.
                    </li>
                    <li>
                      Assist your internet presence in being more visible and
                      connecting with your target audience.
                    </li>
                    <li>
                      Appear alongside your organic initiatives to establish
                      your keyword domination in the internet arena.
                    </li>
                    <li>
                      Work with local SEO tactics to create increased revenue
                      for your organization.
                    </li>
                  </ul>
                </div>
              </div>
              <p style={styles.paraghrapText}>
                Draw Topic Solution is dedicated to guaranteeing that your PPC
                money is never wasted. We employ tried-and-true methods to
                create a cost-effective and planned approach. We employ
                tried-and-true methods to create a cost-effective and planned
                approach.
              </p>
            </section>
          </div>
          {/* --------------  UPDATE CONTENT END -------------------- */}

          {/* ''''''''''''''''''''''''''''''''''' */}
          {/* <div className="blocks-column col-lg-12 col-md-12 col-sm-12">
            <div className="title">
              <h3
                style={{
                  color: "#06092d",
                  fonSize: "2.2rem",
                  fontWeight: 800,
                  textAlign: "center",
                  padding: "0",
                  marginTop: "40px",
                  marginBottom: "30px",
                }}
              >
                Why PPC is Important for Your Business?
              </h3>
              <p className="primary_text" style={{ margin: "40px 10px" }}>
                Pay per click (PPC) allows you to directly reach to your
                targeted audience at right time. You can put your ad in front of
                people who are most likely to be interested in what you are
                offering. You can use various PPC platforms such as Google ads,
                Bing ads, and FB ads etc to advertise on. Following are the
                benefits of PPC for your business:
              </p>
            </div>
            <div className="inner-column">
              <div className="row clearfix">
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/social-media"
                      >
                        Leads
                      </a>
                    </h4>
                    <div className="text">
                      Businesses are completely based on goals. Some company has
                      the goal to either generate profit by selling product and
                      service and some has to get audience traffic. With the
                      right kind of Optimization, keyword placement, regular
                      interaction with customers, sharing etc in Search Engine
                      Optimization, your website starts getting leads. After a
                      speculated time frame, your website starts appearing on
                      the 1st page of Search Engine
                    </div>
                  </div>
                </div>
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/pay-per-click"
                      >
                        Traffic
                      </a>
                    </h4>
                    <div className="text">
                      If you are online visibility is good and the appealing one
                      then the chances of getting traffic on your website become
                      high. The Traffic can be generated through the regular
                      involvement of your digital appearance, which is possible
                      through the best SEO Procedure. It enables you to generate
                      traffic through the right kind of optimization and keyword
                      placements on your website.
                    </div>
                  </div>
                </div>
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="#/content/email-marketing"
                      >
                        Revenue
                      </a>
                    </h4>
                    <div className="text">
                      When your website starts getting Traffic and leads then
                      nobody can stop you to generate maximum revenue return for
                      your Company. It let you enjoy the daily inquiries, sales,
                      and the attention of customers, which will ultimately
                      convert into maximum revenue return. Everyone starts a
                      business with a profit mindset and SEO Brings you closer
                      toward your goal and provides you the best and appealing
                      demonstration in the relevant market only.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* ''''''''''''''''''''''''''''''''''' */}

          {/* ------------------ */}
          {/* <div className="lower-text section-2 primary_text-sm">
            <p>What Makes DrawTopic the Best PPC Company in India</p>
            <div className="container">
              <div className="left">
                We have provided PPC management and marketing services to 100+
                satisfied customers. By continuously getting the quality 5 start
                ratings & feedback from our clients on Upwork, we have become
                India’s Best PPC marketing Company. There are lots of factors
                that made us the prominent Company amongst all such as:
                <ul>
                  <li>We are Google’s Premier Partners </li>
                  <li>Highly Focused Campaigns</li>
                  <li>Customer Centric Approach</li>
                  <li>
                    Tailored marketing solutions as per your business needs{" "}
                  </li>
                  <li>We have an expert PPC advertising team.</li>
                  <li>Prompt and result oriented services</li>
                  <li>
                    Great Portfolio of Clients & long history of successful PPC
                    Campaigns
                  </li>
                  <li>Guaranteed Traffic a speculated time frame</li>
                </ul>
              </div>
              <div
                className="right"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: "300px",
                }}
              >
                <img src="https://webxeros.com/wp-content/uploads/2019/06/why-us-1024x907-2-600x531.png" />
              </div>
            </div>
          </div> */}
          {/* ------------------ */}

          {/* =================== */}

          {/* <div className="lower-text imp-section primary_text-sm">
            <p>Our Specialized PPC Services</p>
            Being a leading PPC management company in India, we provide tailored
            PPC solutions at affordable rates. We will get your brand in front
            of people who are actively searching for what you have to offer. Our
            PPC experts will figure it out which platforms make the most sense
            for your business
            <div>
              <ul className="imp-section__list">
                <li className="imp-section__list-item">
                  <div className="list_image-conatiner">
                    <img src="https://webxeros.com/wp-content/uploads/2019/06/paid-search-advertising.jpg" />
                  </div>
                  <h4>Search Advertising</h4>
                  <div className="body">
                    We will push your brand on the top of search engines.
                    Relevant and well focused campaigns to make sure that your
                    website gets higher visibility, instant traffic and higher
                    conversions rates.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <div className="list_image-conatiner">
                    <img src="https://webxeros.com/wp-content/uploads/2019/06/blue-display.jpg" />
                  </div>
                  <h4>Display Advertising</h4>
                  <div className="body">
                    We will create intelligently-targeted and cost-effective
                    display campaigns to reach your target audience, increase
                    brand awareness and boost your exposure. We will help to
                    improve your sales & overall ROI.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <div className="list_image-conatiner">
                    <img src="https://webxeros.com/wp-content/uploads/2019/06/Social-Media-Advertising.jpg" />
                  </div>
                  <h4>Social Media Advertising</h4>
                  <div className="body">
                    We are experts on advertising on all social media channels
                    including FB, twitter, and Linkedin etc. We develop and
                    implement an innovative social strategies to drive more
                    business & reaching your potential audience.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <div className="list_image-conatiner">
                    <img src="https://webxeros.com/wp-content/uploads/2019/06/A1743.jpg" />
                  </div>
                  <h4>Remarketing</h4>
                  <div className="body">
                    Remarketing is the smart advertising method to show your ads
                    to those users who have visited your website earlier. We
                    will use the advanced techniques to re-engage with your
                    previous users.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <div className="list_image-conatiner">
                    <img src="https://webxeros.com/wp-content/uploads/2019/06/how-does-google-shopping-work.jpg" />
                  </div>
                  <h4>Google Shopping Ads</h4>
                  <div className="body">
                    We use retail-centric approach to manage your shopping ad
                    campaigns. We ensure that you get broader exposure, higher
                    click through rate (CTR), and more sales effectively.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <div className="list_image-conatiner">
                    <img src="https://webxeros.com/wp-content/uploads/2019/06/mobile-ads.jpg" />
                  </div>
                  <h4>Mobile Advertising</h4>
                  <div className="body">
                    Nowadays, millions of users are spending time on surfing the
                    internet on smartphones & tablets. So, it is required to
                    reach them. We will build customized campaigns to reach your
                    targeted audience.
                  </div>
                </li>
              </ul>
            </div>
          </div> */}
          {/* =================== */}

          {/* ''''''''''''''''''''''''''''''''''' */}
          <div
            id="read"
            className="blocks-column col-lg-12 col-md-12 col-sm-12"
          >
            <div className="title">
              <h3
                style={{
                  color: "#06092d",
                  fonSize: "2.2rem",
                  fontWeight: 800,
                  textAlign: "center",
                  padding: "0",
                  marginTop: "40px",
                  marginBottom: "30px",
                }}
              >
                Know Our SEO STRATEGY and Why It is the Best!
              </h3>
              <p className="primary_text" style={{ margin: "40px 10px" }}>
                As a top PPC management firm in India, we offer customized PPC
                solutions at reasonable prices. We expose your brand to
                consumers who are actively looking for what you offer. Our
                strategy enables you to connect directly to the potential
                audience.
              </p>
            </div>
            <div className="inner-column">
              <div className="row clearfix">
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/social-media"
                      >
                        SEARCH ADVERTISING:
                      </a>
                    </h4>
                    <div className="text">
                      We provide sponsored adverts, PPC marketing, search
                      marketing, cost-per-click marketing, and search engine
                      marketing. We drive your company to the top of search
                      engines so that your website receives more visibility,
                      immediate traffic, and greater conversion rates.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/pay-per-click"
                      >
                        DISPLAY ADVERTISING
                      </a>
                    </h4>
                    <div className="text">
                      Display advertising is a powerful marketing tool that
                      conveys a commercial message via using logos, text, films,
                      images, or other visuals. We develop smartly designed
                      campaigns that engage your target audience, raise brand
                      recognition and improve visibility. We assist you in
                      increasing your sales and overall ROI.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="#/content/email-marketing"
                      >
                        SOCIAL MEDIA ADVERTISING:
                      </a>
                    </h4>
                    <div className="text">
                      We promote your business on the most popular social media
                      platforms. A social media advertisement increases a
                      company's reach and engagement. We are professionals in
                      social media advertising, including Facebook, Twitter, and
                      Linkedin, and more.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/content-writing"
                      >
                        REMARKETING:
                      </a>
                    </h4>
                    <div className="text">
                      A professional PPC agency would always promote Google
                      Adword Remarketing. Remarketing is a clever advertising
                      approach that shows your adverts to visitors who have
                      previously visited your website. We re-engage with your
                      past users using modern techniques.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/reputation-management"
                      >
                        MOBILE ADVERTISING:
                      </a>
                    </h4>
                    <div className="text">
                      Mobile advertising has grown in popularity as smartphone
                      and tablet use has skyrocketed. As a result, it is
                      beneficial to approach. To target your intended audience
                      and promote products, We use tailored campaigns, SMS
                      messaging, banner advertisements, and more through mobile
                      advertising.
                    </div>
                  </div>
                </div>

                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/conversion-optimization"
                      >
                        GOOGLE SHOPPING ADS:
                      </a>
                    </h4>
                    <div className="text">
                      With Google Shopping advertisements, you can sell your
                      items in bulk. We have seen great results from google
                      shopping ads. By creating eye-catching commercials, a
                      shopping campaign will help your eCommerce business to
                      sell more.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ''''''''''''''''''''''''''''''''''' */}
        </div>
      </section>
      {/* End Benefit Section */}
      <h6 style={{ ...styles.title }}>
        Are you ready to make your company stand out? Contact us to take
        advantage of PPC to increase clicks and conversions.
      </h6>
    </div>
  );
}

export default PayPerClick;

const styles = {
  title: {
    color: "#06092d",
    fonSize: "2.2rem",
    fontWeight: 800,
    textAlign: "center",
    padding: "0",
    marginTop: "40px",
    marginBottom: "30px",
  },
  paraghrapText: {
    color: "#222",
    fontSize: "1.2rem",
  },
};
