import "../content.css";

import React, { useEffect, useState } from "react";
import pattern3 from "../../images/background/pattern-3.png";
import pattern4 from "../../images/background/pattern-4.png";
import pattern5 from "../../images/background/pattern-5.png";
import pattern6 from "../../images/background/pattern-6.png";
import benefit1 from "../../images/resource/benefit-1.jpg";
import benefit2 from "../../images/resource/benefit-2.png";
import benefit3 from "../../images/resource/benefit-3.jpg";
import cert from "../../images/resource/cert.png";
import cert1 from "../../images/resource/cert1.png";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Spinner from "../../components/spinner/Spinner";
const newLOGO = "new-logo-underline.png";

function ContentMarketing() {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="main__content">
      {/* Benefit Section */}
      <section
        className="benefit-section"
        style={{
          padding: 0,
        }}
      >
        <div
          className="background-layer-one"
          style={{ backgroundImage: `url(${pattern5})` }}
        />
        <div
          className="background-layer-two"
          style={{ backgroundImage: `url(${pattern6})` }}
        />
        <div className="auto-container">
          <div className="row clearfix">
            {/* Images Column */}
            <div className="images-column col-lg-7 col-md-12 col-sm-12">
              <div
                style={{
                  // background: "red",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                }}
                className="inner-column"
              >
                <img
                  width="95%"
                  src={
                    "https://image.freepik.com/free-psd/laptop-iphone-digital-marketing-background_23-2148346689.jpg"
                  }
                />
                {/* <div
                  className="pattern-layer"
                  style={{ backgroundImage: `url(${pattern4})` }}
                />
                <div
                  className="pattern-layer-two"
                  style={{ backgroundImage: `url(${pattern3})` }}
                />
                <div className="color-layer" />
                <div className="image">
                  <img
                    src={
                      "https://image.freepik.com/free-psd/laptop-iphone-digital-marketing-background_23-2148346689.jpg"
                    }
                    alt
                  />
                </div>
                <div className="image-two">
                  <img
                    width="354px"
                    height="337px"
                    src={
                      "https://media-assets-02.thedrum.com/cache/images/thedrum-prod/s3-news-tmp-213062-hallam_digitalmarketing1--default--1280.jpg"
                    }
                    alt
                  />
                </div>
                <div className="image-three">
                  <img width="354px" height="337px" src={benefit2} alt />
                </div> */}
              </div>
            </div>
            {/* Content Column */}
            <div className="content-column col-lg-5 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="sec-title">
                  {/* <div className="title">Learn anything</div> */}
                  <h2 style={{ marginLeft: "10px" }}>Content Marketing</h2>
                </div>
                <ul className="list-style-one">
                  {/* <li><span className="icon flaticon-double-check" /><h3> {t('about')}: </h3> <strong style={{color:"#9e7d18"}}> TM7 SERVICES INC</strong><p>{t('TM7_description')}</p></li>
            <div className="btn-box">
            <a href="https://www.tm7.tech/"
                        target="_blank" className="theme-btn btn-style-two"><span className="txt">{t('find_out_button')} TM7 SERVICES INC</span></a>
          </div> */}
                  <li
                    style={{
                      padding: "20px 60px 0px 10px",
                    }}
                  >
                    <strong style={{ color: "#9e7d18" }}>
                      Content Marketing Services
                    </strong>
                    <p>
                      Nowadays, content marketing has become an important part
                      of the digital marketing strategy. As it has the ability
                      to engage users, generate readers interest, build a strong
                      brand presence, and turn your users into conversions.
                      Webxeros Solutions is a leading content marketing company
                      in India, offering high quality content writing and
                      marketing services all over the world.
                    </p>
                  </li>
                  <div className="btn-box">
                    <a
                      href="#read"
                      // target="_blank"
                      className="theme-btn btn-style-two"
                    >
                      <span className="txt">Read More...</span>
                    </a>
                  </div>
                </ul>
              </div>
            </div>
          </div>

          {/* 000000000000000000 */}
          {/* <div className="lower-text imp-section-colored">
            <p>Our Content Marketing Services Includes:</p>
            <div>
              <ul className="imp-section__list">
                <li className="imp-section__list-item">
                  <h4>Content Strategy</h4>
                  <div className="body">
                    Firstly, our content marketing experts work with you to
                    understand your market, and business goals. On the basis of
                    market research and audience targeting, our experts create a
                    custom content marketing strategy to meets your specific
                    business needs. We develop unique, appealing, valuable &amp;
                    evergreen content that brings high quality traffic and keep
                    your readers engaged with the website. We improve your
                    business&#8217;s search visibility and brand awareness.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <h4>Content Creation</h4>
                  <div className="body">
                    Our next step involves high quality content, meaningful and
                    relevant content creations. Our specialized writers will
                    create unique and keyword rich content based on
                    Google&#8217;s latest guidelines. Our services include
                    landing pages content, blog posts, infographics content,
                    article writing, press release content, and guest posting
                    content as well. After that, our team of editors will
                    proofread the entire content to deliver you error free &amp;
                    quality content.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <h4>Management &amp; Scheduling</h4>
                  <div className="body">
                    Management and scheduling are required to deliver every
                    project successfully. We specialize in content management
                    and we make the entire process easier by right distribution.
                    We make the perfect coordination between the writers,
                    editors, and marketers. We have a team of digital marketing
                    specialists who are experts in managing &amp; scheduling. We
                    create a content calendar, establish a deadline for writing
                    and delivering.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <h4>Content Optimization</h4>
                  <div className="body">
                    Content is useless without optimization. Content
                    optimization is required to make it more valuable, increase
                    search visibility and to rank high in the SERPs. We will
                    optimize your meta titles, meta descriptions, heading tags
                    and text, etc according to the keywords. We will spread out
                    your targeted keywords throughout all the content so that it
                    can bring quality traffic to your website.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <h4>Content Distribution</h4>
                  <div className="body">
                    Just creating valuable words is not enough, distributing
                    them on the right channels also equally important. We not
                    only offer you reliable &amp; high quality content writing
                    services but also reach to your targeted audience through
                    effective marketing channels. We are experts in PR
                    promotions, sponsored blog posting, and social media
                    marketing, etc. We promote your content on popular social
                    networking platforms to boost brand awareness and traffic.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <h4>Tracking &amp; Monthly Reporting</h4>
                  <div className="body">
                    We don&#8217;t stop working after creating &amp;
                    distributing the content. We keep an eye on your content
                    marketing campaign and optimize it on a regular basis to
                    ensure the maximum performance. We create custom reporting
                    and keep sending you on a monthly basis. So that you can
                    monitor your ROI and our performance. We use various
                    reporting tools to analyze metrics such as blog visits, site
                    visits, user engagement, keywords progress, lead generation,
                    customer engagement, etc
                  </div>
                </li>
              </ul>
            </div>
          </div> */}

          {/* 000000000000000000 */}

          {/* ------------------ */}
          {/* <div className="lower-text section-2 primary_text-sm">
            <p>Why choose Draw Topic ?</p>
            <div className="container">
              <div className="left">
                Nowadays, content marketing has become an important part of the
                digital marketing strategy. As it has the ability to engage
                users, generate readers interest, build a strong brand presence,
                and turn your users into conversions. Webxeros Solutions is a
                leading content marketing company in India, offering high
                quality content writing and marketing services all over the
                world.
              </div>
              <div className="right">
                <img src="https://webxeros.com/wp-content/uploads/2019/06/content-600x372.jpg" />
              </div>
            </div>
          </div> */}
          {/* ------------------ */}

          {/* ''''''''''''''''''''''''''''''''''' */}
          <div
            id="read"
            className="blocks-column col-lg-12 col-md-12 col-sm-12"
          >
            <div className="title">
              <h3
                style={{
                  color: "#06092d",
                  fonSize: "2.2rem",
                  fontWeight: 800,
                  textAlign: "center",
                  padding: "0",
                  marginTop: "40px",
                  marginBottom: "70px",
                }}
              >
                Our Content Marketing Services Includes:
              </h3>
            </div>
            <div className="inner-column">
              <div className="row clearfix">
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/social-media"
                      >
                        Content Strategy
                      </a>
                    </h4>
                    <div className="text">
                      Firstly, our content marketing experts work with you to
                      understand your market, and business goals. On the basis
                      of market research and audience targeting, our experts
                      create a custom content marketing strategy to meets your
                      specific business needs. We develop unique, appealing,
                      valuable & evergreen content that brings high quality
                      traffic and keep your readers engaged with the website. We
                      improve your business’s search visibility and brand
                      awareness.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/pay-per-click"
                      >
                        Content Creation
                      </a>
                    </h4>
                    <div className="text">
                      Our next step involves high quality content, meaningful
                      and relevant content creations. Our specialized writers
                      will create unique and keyword rich content based on
                      Google’s latest guidelines. Our services include landing
                      pages content, blog posts, infographics content, article
                      writing, press release content, and guest posting content
                      as well. After that, our team of editors will proofread
                      the entire content to deliver you error free & quality
                      content.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="#/content/email-marketing"
                      >
                        Management & Scheduling
                      </a>
                    </h4>
                    <div className="text">
                      Management and scheduling are required to deliver every
                      project successfully. We specialize in content management
                      and we make the entire process easier by right
                      distribution. We make the perfect coordination between the
                      writers, editors, and marketers. We have a team of digital
                      marketing specialists who are experts in managing &
                      scheduling. We create a content calendar, establish a
                      deadline for writing and delivering.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/content-writing"
                      >
                        Content Optimization
                      </a>
                    </h4>
                    <div className="text">
                      Content is useless without optimization. Content
                      optimization is required to make it more valuable,
                      increase search visibility and to rank high in the SERPs.
                      We will optimize your meta titles, meta descriptions,
                      heading tags and text, etc according to the keywords. We
                      will spread out your targeted keywords throughout all the
                      content so that it can bring quality traffic to your
                      website.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/reputation-management"
                      >
                        Content Distribution
                      </a>
                    </h4>
                    <div className="text">
                      Just creating valuable words is not enough, distributing
                      them on the right channels also equally important. We not
                      only offer you reliable & high quality content writing
                      services but also reach to your targeted audience through
                      effective marketing channels. We are experts in PR
                      promotions, sponsored blog posting, and social media
                      marketing, etc. We promote your content on popular social
                      networking platforms to boost brand awareness and traffic.
                    </div>
                  </div>
                </div>

                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/conversion-optimization"
                      >
                        Tracking & Monthly Reporting
                      </a>
                    </h4>
                    <div className="text">
                      We don’t stop working after creating & distributing the
                      content. We keep an eye on your content marketing campaign
                      and optimize it on a regular basis to ensure the maximum
                      performance. We create custom reporting and keep sending
                      you on a monthly basis. So that you can monitor your ROI
                      and our performance. We use various reporting tools to
                      analyze metrics such as blog visits, site visits, user
                      engagement, keywords progress, lead generation, customer
                      engagement, etc
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ''''''''''''''''''''''''''''''''''' */}
        </div>
      </section>
      {/* End Benefit Section */}
    </div>
  );
}

export default ContentMarketing;
