import React, { useEffect, useRef } from "react";
// import logo from "../../assets/logo/logo.png";
import logo from "../assets/logo/logo.png";
import "../components/header/header.css";
import { NavLink, Link, useHistory } from "react-router-dom";
import { ABOUT_US, CONTACT_US, FEATURES, HOME } from "../constants/routePaths";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import classNames from "classnames";
import Features from "../views/features/Features";
import Contact from "../views/contact/Contact";
import About from "../views/about/About";
import Demo from "../views/demo/Demo";
import Pricing from "../views/pricing/Pricing";
import Home from "../views/home/Home";
import Faq from "../views/faq/Faq";
import { SOCIAL_LINKS } from "../constants/socialLinks";
import { AlternateEmail } from "@material-ui/icons";

const languages = [
  {
    code: "sp",
    name: "spanish",
    country_code: "es",
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
];

const newLOGO = "/new-logo-underline.png";
function TrueHeader() {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  const history = useHistory();

  const megaMenuRef = useRef(null);
  const myMobileNavRef = useRef(null);
  const subMenuRef = useRef(null);

  useEffect(() => {
    console.log("Setting page stuff");
    document.body.dir = currentLanguage.dir || "ltr";
  }, [currentLanguage, t]);

  const closeNav = () => {
    myMobileNavRef.current.style.width = "0";
  };
  const openNav = () => {
    myMobileNavRef.current.style.width = "300px";
  };

  const handleSubLink = (toggle) => {
    if (toggle === "toggle") {
      subMenuRef.current.classList.toggle("hide");
    }

    if (toggle === "hide") {
      console.log("hide", subMenuRef.current);
      subMenuRef.current.classList.add("hide");
    }
  };

  const goHome = () => {
    history.push("/");
  };

  const handleNavLinkClick = () => {
    megaMenuRef.current.style.opacity = "0";
    megaMenuRef.current.style.transform = "scaleY(0)";
  };

  const handleNavLinkOpen = () => {
    megaMenuRef.current.style.opacity = "1";
    megaMenuRef.current.style.transform = "scaleY(1)";
  };

  return (
    <header style={{ position: "sticky", top: "0" }} className="main-header">
      {/*Header-Upper*/}
      <div className="header-upper">
        <div className="outer-container clearfix  header__conatiner">
          {/* <div className="pull-left logo-box"> */}

          <div className="logo">
            <Link exact to="/">
              <img
                style={{ marginTop: "100px" }}
                height="100px"
                width="300px"
                src={newLOGO}
                alt
                title
              />
            </Link>
          </div>

          {/* </div> */}

          {/* ------------ nav ------------------ */}
          <span className="myMobileNav" onClick={openNav}>
            <img
              src="https://cdn4.iconfinder.com/data/icons/wirecons-free-vector-icons/32/menu-alt-512.png"
              width="40px"
              //   style={{ right: "-1000px !important" }}
            />
          </span>

          <div
            id="mySidenav"
            ref={myMobileNavRef}
            class="sidenav"
            onMouseLeave={closeNav}
          >
            <div
              style={{
                display: "flex",
                width: "300px",
                position: "relative",
                paddingTop: "20px",
              }}
            >
              {/* <img src={newLOGO} onClick={goHome} alt="logo" width="230px" /> */}
              <a href="javascript:void(0)" class="closebtn" onClick={closeNav}>
                &times;
              </a>
            </div>
            <button
              className="drop-btn"
              onClick={() => handleSubLink("toggle")}
            >
              SERVICES
              <i className="fa fa-arrow-down"></i>
            </button>
            <div
              className="sub-links hide"
              ref={subMenuRef}
              onMouseLeave={() => handleSubLink("hide")}
            >
              <Link
                onClick={closeNav}
                to="/content/search-engine-optimization"
                className="mobile-link"
              >
                SEARCH ENGINE OPTIMIZATION
              </Link>
              <Link
                onClick={closeNav}
                to="/content/reputation-management"
                className="mobile-link"
              >
                ONLINE REPUTATION MANAGEMENT
              </Link>
              <Link
                onClick={closeNav}
                to="/content/ASO"
                className="mobile-link"
              >
                ASO
              </Link>
              <Link
                onClick={closeNav}
                to="/content/email-marketing"
                className="mobile-link"
              >
                EMAIL MARKETING
              </Link>
              <Link
                onClick={closeNav}
                to="/content/content-writing"
                className="mobile-link"
              >
                CONTENT WRITING
              </Link>
              <Link
                onClick={closeNav}
                to="/content/content-marketing"
                className="mobile-link"
              >
                CONTENT MARKETING
              </Link>
              <Link
                onClick={closeNav}
                to="/content/pay-per-click"
                className="mobile-link"
              >
                PAY PER CLICK – PPC
              </Link>
              <Link
                onClick={closeNav}
                to="/content/social-media"
                className="mobile-link"
              >
                SOCIAL MEDIA MARKETING
              </Link>
            </div>
            <Link to="/" className="mobile-link">
              Home
            </Link>
            <Link to="/#about" className="mobile-link">
              About Us
            </Link>
            <Link to="/#contact" className="mobile-link">
              Contact Us
            </Link>
          </div>

          {/* ------------ nav end ------------------ */}

          <div className="nav-outer clearfix">
            {/*Mobile Navigation Toggler*/}
            <div className="mobile-nav-toggler">
              <span className="icon flaticon-menu" />
            </div>
            {/* Main Menu */}
            <nav className="main-menu navbar-expand-md">
              <div className="navbar-header">
                {/* Toggle Button */}
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                </button>
              </div>
              <div
                className="navbar-collapse collapse clearfix"
                id="navbarSupportedContent"
              >
                <ul className="navigation clearfix">
                  <li style={{ margin: 0 }}>
                    <div>
                      <img
                        onClick={() => {
                          history.push("/");
                        }}
                        src={newLOGO}
                        style={{ width: "180px" }}
                      />
                    </div>
                  </li>
                  <li
                    className="dropdown has-mega-menu"
                    onMouseOver={handleNavLinkOpen}
                    onMouseOut={handleNavLinkClick}
                  >
                    <a href="#features">
                      <span>
                        Services <i className="fa fa-arrow-down" />
                      </span>
                    </a>

                    <div
                      className="mega-menu"
                      id="mega-menu"
                      ref={megaMenuRef}
                      style={
                        {
                          // maxHeight: "230px",
                          // 160px
                        }
                      }
                    >
                      {/* Upper Box */}
                      <div className="upper-box">
                        <div className="page-links-box">
                          <Link
                            to="/content/search-engine-optimization"
                            className="link"
                            style={styles.megaLink}
                            onClick={handleNavLinkClick}
                          >
                            <i class="fa fa-search" aria-hidden="true"></i>
                            SEARCH ENGINE OPTIMIZATION – SEO
                          </Link>

                          <Link
                            to="/content/ASO"
                            className="link"
                            style={styles.megaLink}
                            onClick={handleNavLinkClick}
                          >
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                            ASO
                          </Link>
                          <Link
                            to="/content/social-media"
                            className="link"
                            style={styles.megaLink}
                            onClick={handleNavLinkClick}
                          >
                            <i class="fa fa-hand-o-up" aria-hidden="true"></i>
                            SOCIAL MEDIA MARKETING
                          </Link>
                          <Link
                            to="/content/pay-per-click"
                            className="link"
                            style={styles.megaLink}
                            onClick={handleNavLinkClick}
                          >
                            <span className="icon flaticon-software" />
                            PAY PER CLICK – PPC
                          </Link>
                          <Link
                            to="/content/email-marketing"
                            className="link"
                            style={styles.megaLink}
                            onClick={handleNavLinkClick}
                          >
                            {/* <i
                                class="fa fa-video-camera"
                                aria-hidden="true"
                              ></i> */}
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                            {/* VIDEO PRODUCTION */}
                            EMAIL MARKETING
                          </Link>
                          <Link
                            onClick={handleNavLinkClick}
                            to="/content/content-writing"
                            className="link"
                            style={styles.megaLink}
                          >
                            <i
                              class="fa fa-pencil-square-o"
                              aria-hidden="true"
                            ></i>
                            CONTENT WRITING
                          </Link>
                          <Link
                            onClick={handleNavLinkClick}
                            to="/content/content-marketing"
                            className="link"
                            style={styles.megaLink}
                          >
                            <span className="icon flaticon-team" />
                            CONTENT MARKETING
                          </Link>
                          <Link
                            onClick={handleNavLinkClick}
                            to="/content/reputation-management"
                            className="link"
                            style={styles.megaLink}
                          >
                            <span className="icon flaticon-language" />
                            {/* REPUTATION MANAGEMENT */}
                            ONLINE REPUTATION MANAGEMENT
                          </Link>
                          {/* <a href="#" className="link"><span className="icon flaticon-healthcare" />{t('discussion_forum')}</a> */}
                        </div>
                      </div>
                      {/* Lower Box */}
                      {/* <div className="lower-box">
                        <h3>Become an Instructor</h3>
                        <div className="text">Top instructors from around the Neque convallis a cras semper auctor. <br /> Libero id faucibus nisl tincidunt egetnvallis </div>
                        <div className="btn-box">
                          <a href="#" className="theme-btn btn-style-five">Start teaching today</a>
                        </div>
                        <div className="side-icon">
                          <img src="images/resource/mega-menu-icon.png" alt />
                        </div>
                      </div> */}
                    </div>
                  </li>
                  <li className="current dropdown">
                    <Link to="/">{t("home")} </Link>
                    {/* <ul>
                      <li><a href="index.html">Home page 01</a></li>
                      <li><a href="index-2.html">Home page 02</a></li>
                      <li className="dropdown"><a href="#">Header styles</a>
                        <ul>
                          <li><a href="index.html">Header Style 01</a></li>
                          <li><a href="index-2.html">Header Style 02</a></li>
                        </ul>
                      </li>
                    </ul> */}
                  </li>
                  <li className="dropdown">
                    {/* <a href="/#about">{t("about_us")}</a> */}
                    <Link to="/#about">{t("about_us")}</Link>
                    {/* <ul>
                      <li><a href="about.html">About Us</a></li>
                      <li><a href="faq.html">Faq</a></li>
                      <li><a href="teacher.html">Teacher</a></li>
                      <li><a href="profile.html">User Profile</a></li>
                      <li><a href="membership.html">Membership</a></li>
                    </ul> */}
                  </li>
                  {/* <li className="dropdown"><a href="#features" >{t('features')} </a>
                    <ul>
                      <li><a href="course.html">Courses</a></li>
                      <li><a href="course-2.html">Courses 02</a></li>
                      <li><a href="course-3.html">Courses 03</a></li>
                      <li><a href="course-4.html">Courses 04</a></li>
                      <li><a href="course-detail.html">Courses Detail</a></li>
                    </ul>
                  </li> */}
                  <li className="dropdown">
                    {/* <a href="#contact">{t("contact_us")}</a> */}
                    <Link to="/#contact">{t("contact_us")}</Link>
                    {/* <ul>
                      <li><a href="blog.html">Our Blog</a></li>
                      <li><a href="blog-list.html">Blog List</a></li>
                      <li><a href="blog-detail.html">Blog Detail</a></li>
                      <li><a href="not-found.html">Not Found</a></li>
                    </ul> */}
                  </li>

                  <li style={{ marginTop: "15px" }} class="dropdown">
                    {/* <button style={{color:"rgb(67,185,126)"}}  class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  <GlobeIcon />
  </button> */}
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <span className="dropdown-item-text">
                          {t("language")}
                        </span>
                      </li>
                      {languages.map(({ code, name, country_code }) => (
                        <li key={country_code}>
                          <a
                            href="#"
                            className={classNames("dropdown-item", {
                              disabled: currentLanguageCode === code,
                            })}
                            onClick={() => {
                              i18next.changeLanguage(code);
                            }}
                          >
                            <span
                              className={`flag-icon flag-icon-${country_code} mx-2`}
                              style={{
                                opacity: currentLanguageCode === code ? 0.5 : 1,
                              }}
                            ></span>
                            {name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </div>
            </nav>
            {/* Main Menu End*/}
            <div className="outer-box clearfix">
              {/* Search */}
              {/* <div className="search-box">
                <form method="post" action="contact.html">
                  <div className="form-group">
                    <input type="search" name="search-field" defaultValue placeholder="What do we want learn" required />
                    <button type="submit"><span className="icon fa fa-search" /></button>
                  </div>
                </form>
              </div> */}
              {/* Social Box */}
              <ul className="social-box">
                <li className="instagram">
                  <a
                    target="_blank"
                    href={SOCIAL_LINKS?.instagram || ""}
                    className="fa fa-instagram"
                  />
                </li>
                <li className="facebook">
                  <a
                    target="_blank"
                    href={SOCIAL_LINKS?.facebook || ""}
                    className="fa fa-facebook-f"
                  />
                </li>
                <li className="twitter">
                  <a
                    target="_blank"
                    href={SOCIAL_LINKS?.twitter || ""}
                    className="fa fa-twitter"
                  />
                </li>
                <li className="linkedIn">
                  <a
                    target="_blank"
                    href={SOCIAL_LINKS?.linkedin || ""}
                    className="fa fa-linkedin"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/*End Header Upper*/}
      {/* Sticky Header  */}
      <div className="sticky-header">
        <div className="auto-container clearfix">
          {/*Logo*/}
          <div className="logo pull-left">
            <a href="index.html" title>
              <img src="images/logo.png" alt title />
            </a>
          </div>
          {/*Right Col*/}
          <div className="pull-right">
            {/* Main Menu */}
            <nav className="main-menu">
              {/*Keep This Empty / Menu will come through Javascript*/}
            </nav>
            {/* Main Menu End*/}
            {/* Main Menu End*/}
            <div className="outer-box clearfix"></div>
          </div>
        </div>
      </div>
      {/* End Sticky Menu */}
      {/* Mobile Menu  */}
      <div className="mobile-menu">
        <div className="menu-backdrop" />
        <div className="close-btn">
          <span className="icon flaticon-multiply" />
        </div>
        <nav className="menu-box">
          <div className="nav-logo">
            <a href="index.html">
              <img src="images/logo.png" alt title />
            </a>
          </div>
          <div className="menu-outer">
            {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
          </div>
        </nav>
      </div>
      {/* End Mobile Menu */}
    </header>
  );
}

export default TrueHeader;
const styles = {
  megaLink: {
    padding: "5px",
    fontSize: "1rem",
  },
};
