import React, { useState } from "react";
import pattern12 from "../../assets/images/background/pattern-12.png";
import pattern13 from "../../assets/images/background/pattern-13.png";
import pattern14 from "../../assets/images/background/pattern-14.png";
import logo from "../../assets/logo/logo.png";
import { Link } from "react-router-dom";
import * as validator from "../../utilities/validator";
import { SOCIAL_LINKS } from "../../constants/socialLinks";
const initialMsgData = {
  email: "",
};

const newLOGO = "/new-logo-underline.png";
console.log(newLOGO, "newLogo");

const Footer = () => {
  const [loading, setLoading] = useState(false);
  const [finalValidate, setfinalValidate] = useState(false);
  const [msgData, setMsgData] = useState({});
  const [responseMsg, setResponseMsg] = useState({ color: "", msg: "" });
  const [errdata, setErrdata] = useState(initialMsgData);

  const setValues = (e) => {
    let errObj = {};
    const { name, value } = e.target;

    setMsgData({ ...msgData, [name]: value });

    setResponseMsg({ ...responseMsg, msg: "" });

    switch (name) {
      case "email": {
        if (validator.isValidEmail(value)) {
          errObj = { ...errdata, [name]: false };

          setErrdata(errObj);
        } else {
          errObj = { ...errdata, [name]: true };
          setErrdata(errObj);
        }
        break;
      }
      case "name": {
        if (validator.nameValid(value)) {
          errObj = { ...errdata, [name]: false };
          setErrdata(errObj);
        } else {
          errObj = { ...errdata, [name]: true };
          setErrdata(errObj);
        }
        break;
      }
      case "message": {
        if (validator.isFieldEmpty(value)) {
          errObj = { ...errdata, [name]: false };

          setErrdata(errObj);
        } else {
          errObj = { ...errdata, [name]: true };
          setErrdata(errObj);
        }
        break;
      }
      default: {
      }
    }
  };

  const sendDetails = async () => {
    setLoading(true);
    console.log(msgData);
    // const query = `http://18.224.243.74:4603/api/users/subscribeToUs`;
    const query = `https://drawtopic.digital:4603/api/users/subscribeToUs`;
    console.log(JSON.stringify(msgData));
    try {
      const response = await fetch(query, {
        method: "POST",
        body: JSON.stringify(msgData),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      setLoading(false);

      const responseObj = await response.json();

      console.log(responseObj);
      if (responseObj.isSuccess == true) {
        setMsgData(initialMsgData);
        setErrdata(initialMsgData);
        setResponseMsg({ color: "green", msg: "Details sent Successfully" });
        setfinalValidate(true);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setResponseMsg({ color: "red", msg: "Something went wrong" });
    }
  };

  const submit = (e) => {
    e.preventDefault();
    const finalValid = Object.values(errdata).every((value) => value === true);
    console.log("checkkkk", finalValid);

    if (finalValid) {
      sendDetails();
    } else {
      setResponseMsg({
        color: "red",
        msg: "Please Provide Your Email",
      });
    }
  };

  return (
    <>
      <footer className="main-footer">
        <div className="circle-layer" />
        <div
          className="pattern-layer-one"
          style={{ backgroundImage: `url(${pattern12})` }}
        />
        <div
          className="pattern-layer-two"
          style={{ backgroundImage: `url(${pattern13})` }}
        />
        <div
          className="pattern-layer-three"
          style={{ backgroundImage: `url(${pattern14})` }}
        />
        <div
          className="pattern-layer-four"
          style={{ backgroundImage: `url(${pattern14})` }}
        />
        <div className="auto-container">
          {/*Widgets Section*/}
          <div className="widgets-section">
            <div className="row clearfix">
              {/* Footer Column */}
              <div className="footer-column col-lg-5 col-md-12 col-sm-12">
                <div className="footer-widget logo-widget">
                  <div className="logo">
                    <a href="index.html">
                      {/* <img src={logo} alt /> */}

                      <img src={newLOGO} alt />
                    </a>
                  </div>
                  <ul className="info-list">
                    <li>
                      Tel:
                      <a href="tel:+0845-371-02-02">
                        {" "}
                        {`(+91)-${SOCIAL_LINKS.phone_no}`}
                      </a>
                    </li>
                    <li>
                      Email:
                      <a href="mailto:info@yoursite.co.uk">
                        {SOCIAL_LINKS.email}
                      </a>
                    </li>
                  </ul>
                  {/* Social Box */}
                  <ul className="social-box">
                    <li className="instagram">
                      <a
                        target="_blank"
                        style={{ backgroundColor: "#E4405F" }}
                        href={SOCIAL_LINKS?.instagram || ""}
                        className="fa fa-instagram"
                      />
                    </li>
                    <li className="facebook">
                      <a
                        target="_blank"
                        href={SOCIAL_LINKS?.facebook || ""}
                        className="fa fa-facebook-f"
                      />
                    </li>
                    <li className="twitter">
                      <a
                        target="_blank"
                        href={SOCIAL_LINKS?.twitter || ""}
                        className="fa fa-twitter"
                      />
                    </li>
                    <li className="linkedIn">
                      <a
                        target="_blank"
                        href={SOCIAL_LINKS?.linkedin || ""}
                        className="fa fa-linkedin"
                      />
                    </li>
                  </ul>
                </div>
              </div>
              {/* Footer Column */}
              <div className="footer-column col-lg-7 col-md-12 col-sm-12">
                <div className="row clearfix">
                  {/* Column */}
                  <div className="column col-lg-4 col-md-4 col-sm-12">
                    <h5>About</h5>
                    <ul className="list">
                      <li>
                        <a href="#about">About </a>
                      </li>
                      {/* <li><a href="#">News</a></li>
                <li><a href="#">Impact</a></li>
                <li><a href="#">Our team</a></li>
                <li><a href="#">Our interns</a></li> */}
                    </ul>
                  </div>
                  {/* Column */}
                  <div className="column col-lg-4 col-md-4 col-sm-12">
                    <h5>Need some help?</h5>
                    <ul className="list">
                      <li>
                        <a href="#faq">FAQ </a>
                      </li>

                      <li>
                        <a href="#contact">Contact </a>
                      </li>
                      {/* <li><a href="#">Child safety</a></li>
                <li><a href="#">Help Centre</a></li>
                <li><a href="#">Academy</a></li> */}
                    </ul>
                  </div>
                  {/* Column */}
                  {/* <div className="column col-lg-4 col-md-4 col-sm-12">
              <h5>Courses</h5>
              <ul className="list">
                <li><a href="#">Khan Kids app</a></li>
                <li><a href="#">Science &amp; engineering</a></li>
                <li><a href="#">Computing</a></li>
                <li><a href="#">Arts &amp; humanities</a></li>
                <li><a href="#">Economics &amp; finance</a></li>
              </ul>
            </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* Lower Box */}
          <div className="lower-box">
            <div className="row clearfix">
              <div className="col-lg-6 col-lg-6 col-sm-12">
                {/* Subscribe Form */}
                <div className="subscribe-form">
                  <h6>Newsletter</h6>
                  <form method="post" action="contact.html">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        value={msgData.email || ""}
                        onChange={(e) => setValues(e)}
                        placeholder="Your email"
                        required
                      />
                      <p style={{ color: "red", fontSize: "14px" }}>
                        {errdata.email === false && "Enter a valid email"}
                      </p>

                      <button onClick={(e) => submit(e)} className="submit-btn">
                        {loading ? (
                          <div
                            style={{ fontSize: "10px" }}
                            className="spinner-border"
                            role="status"
                          >
                            <span className="visually-hidden"></span>
                          </div>
                        ) : (
                          <span className="icon flaticon-right-arrow-2" />
                        )}
                      </button>
                      <small
                        style={{
                          fontSize: "14px",
                          paddingTop: "5px",
                          color: responseMsg.color,
                          display: "block",
                        }}
                      >
                        {responseMsg.msg}
                      </small>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-6 col-lg-6 col-sm-12">
                <div className="text">
                  Need to train your team? We offer flexible, cost-effective{" "}
                  <br /> group memberships for your business
                </div>
                {/* <a href="#" className="singup">Free Singup</a> */}
              </div>
            </div>
          </div>
          {/* Footer Bottom */}
          <div className="footer-bottom">
            <div className="row clearfix">
              {/* Copyright Column */}
              <div className="copyright-column col-lg-6 col-md-12 col-sm-12">
                <div className="copyright">
                  Copyright © 2021 drawtopic.digital Pvt. Ltd
                </div>
              </div>
              {/* Nav Column */}
              <div className="nav-column col-lg-6 col-md-12 col-sm-12">
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.privacypolicygenerator.info/live.php?token=cbbnufU0pLrsYXSePox07VjLSnbmGmhZ"
                    >
                      Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
