import React, { useEffect } from "react";
import pattern4 from "../../assets/images/main-slider/pattern-4.png";
import pattern5 from "../../assets/images/main-slider/pattern-5.png";
import icon2 from "../../assets/images/main-slider/icon-2.png";
import pattern1 from "../../assets/images/main-slider/pattern-1.png";
import pattern2 from "../../assets/images/main-slider/pattern-2.png";
import icon1 from "../../assets/images/main-slider/icon-1.png";
import pattern3 from "../../assets/images/main-slider/pattern-3.png";
import pattern6 from "../../assets/images/main-slider/pattern-6.png";
import image1 from "../../assets/images/main-slider/image-1.png";
import image4 from "../../assets/images/main-slider/image-4.png";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import "./home.css";

const languages = [
  {
    code: "sp",
    name: "spanish",
    country_code: "es",
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
];

const Home = () => {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    console.log("Setting page stuff");
    document.body.dir = currentLanguage.dir || "ltr";
  }, [currentLanguage, t]);

  return (
    <>
      <section className="banner-section">
        <div className="auto-container">
          <div
            className="pattern-layer-six"
            style={{ backgroundImage: `url(${pattern4})` }}
          />
          <div
            className="pattern-layer-seven"
            style={{ backgroundImage: `url(${pattern5})` }}
          />
          <div
            className="pattern-layer-eight"
            style={{ backgroundImage: `url(${icon2})` }}
          />
          <div className="row clearfix">
            {/* Image Column */}
            <div className="image-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <div
                  className="pattern-layer-one"
                  style={{ backgroundImage: `url(${pattern1})` }}
                />
                <div
                  className="pattern-layer-two"
                  style={{ backgroundImage: `url(${pattern2})` }}
                />
                <div
                  className="pattern-layer-three"
                  style={{ backgroundImage: `url(${icon1})` }}
                />
                <div
                  className="pattern-layer-four"
                  style={{ backgroundImage: `url(${pattern3})` }}
                />
                <div
                  className="pattern-layer-five"
                  style={{ backgroundImage: `url(${icon2})` }}
                />
                <div className="image">
                  <img
                    style={{ borderRadius: "30%" }}
                    src="https://miro.medium.com/max/3200/0*f-YBCfsCVkm9meEK"
                    alt
                  />
                </div>
                <div className="image-two">
                  <img src={image4} alt />
                </div>
                {/* <div className="image-content" style={{backgroundImage: `url(${pattern6})`}}>
                <p style={{fontSize:"20px"}}>{t('87%_of')}<br /> {t('learning')}</p>
              </div> */}
              </div>
            </div>
            {/* Content Column */}
            <div className="content-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="title">
                  RESULTS ORIENTED DIGITAL MARKETING AGENCY{" "}
                </div>
                <h1>Draw Topic Digital</h1>
                <div className="btns-box">
                  <h5>
                    Draw Topic Digital is dedicated to learning about and
                    understanding your business. By building a relationship with
                    each of our clients, we can ensure our marketing strategy is
                    built with the sole focus of solving the problems your
                    business faces. Each of our marketing campaigns is built
                    with the client's needs in mind to solve the greatest
                    marketing problems.
                  </h5>
                  <hr />
                  <h4 style={{ fontWeight: "700" }}>
                    OUR COMMITMENT We are fully committed to elevating our
                    clients to new heights.
                  </h4>
                </div>
                <div className="btns-box">
                  <a href="#contact" className="theme-btn btn-style-one">
                    <span className="txt">Contact Us</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
