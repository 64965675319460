import "../content.css";

import React, { useEffect, useState } from "react";
import pattern3 from "../../images/background/pattern-3.png";
import pattern4 from "../../images/background/pattern-4.png";
import pattern5 from "../../images/background/pattern-5.png";
import pattern6 from "../../images/background/pattern-6.png";
import benefit1 from "../../images/resource/benefit-1.jpg";
import benefit2 from "../../images/resource/benefit-2.png";
import benefit3 from "../../images/resource/benefit-3.jpg";
import cert from "../../images/resource/cert.png";
import cert1 from "../../images/resource/cert1.png";
import "./social_media.css";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Spinner from "../../components/spinner/Spinner";
const newLOGO = "new-logo-underline.png";

function SocialMedia() {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="main__content">
      {/* Benefit Section */}
      <section
        className="benefit-section"
        style={{
          padding: 0,
        }}
      >
        <div
          className="background-layer-one"
          style={{ backgroundImage: `url(${pattern5})` }}
        />
        <div
          className="background-layer-two"
          style={{ backgroundImage: `url(${pattern6})` }}
        />
        <div className="auto-container">
          <div className="row clearfix">
            {/* Images Column */}
            <div className="images-column col-lg-7 col-md-12 col-sm-12">
              <div
                style={{
                  // background: "red",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                }}
                className="inner-column"
              >
                <img
                  width="95%"
                  src={
                    "https://image.freepik.com/free-psd/laptop-iphone-digital-marketing-background_23-2148346689.jpg"
                  }
                />
                {/* <div
                  className="pattern-layer"
                  style={{ backgroundImage: `url(${pattern4})` }}
                />
                <div
                  className="pattern-layer-two"
                  style={{ backgroundImage: `url(${pattern3})` }}
                />
                <div className="color-layer" />
                <div className="image">
                  <img
                    src={
                      "https://image.freepik.com/free-psd/laptop-iphone-digital-marketing-background_23-2148346689.jpg"
                    }
                    alt
                  />
                </div>
                <div className="image-two">
                  <img
                    width="354px"
                    height="337px"
                    src={
                      "https://media-assets-02.thedrum.com/cache/images/thedrum-prod/s3-news-tmp-213062-hallam_digitalmarketing1--default--1280.jpg"
                    }
                    alt
                  />
                </div>
                <div className="image-three">
                  <img width="354px" height="337px" src={benefit2} alt />
                </div> */}
              </div>
            </div>
            {/* Content Column */}
            <div className="content-column col-lg-5 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="sec-title">
                  {/* <div className="title">Learn anything</div> */}
                  <h2 style={{ marginLeft: "10px" }}>Social Media Marketing</h2>
                </div>
                <ul className="list-style-one">
                  {/* <li><span className="icon flaticon-double-check" /><h3> {t('about')}: </h3> <strong style={{color:"#9e7d18"}}> TM7 SERVICES INC</strong><p>{t('TM7_description')}</p></li>
            <div className="btn-box">
            <a href="https://www.tm7.tech/"
                        target="_blank" className="theme-btn btn-style-two"><span className="txt">{t('find_out_button')} TM7 SERVICES INC</span></a>
          </div> */}
                  <li
                    style={{
                      padding: "20px 60px 0px 10px",
                    }}
                  >
                    <strong style={{ color: "#9e7d18" }}>
                      Social Media Marketing
                    </strong>
                    <p>
                      Facebook, Twitter, Instagram, among several other social
                      media platforms have become such a vital part of people's
                      lives that it's no surprise that they can work quite well
                      for businesses. Today these networks are effective tools
                      for our businesses, and if you're not engaging on any of
                      them, you're not worth being in business!
                      <br />
                      Businesses are expanding globally facilitated by social
                      media platforms. You may simply interact with your clients
                      and grow your business with the aid of social media
                      marketing. Our services will assist you with all areas of
                      your SMO plan, including social media content creation,
                      site management, development, and social media marketing
                      approach.
                    </p>
                  </li>
                  <div className="btn-box">
                    <a
                      href="#READMORE"
                      // target="_blank"
                      className="theme-btn btn-style-two"
                    >
                      <span className="txt">Read More...</span>
                    </a>
                  </div>
                </ul>
              </div>
            </div>
          </div>
          {/* Lower Text */}
          {/* <div className="lower-text">
            <p>
              Our Social Media Marketing Services
              <br />
            </p>
            <div
              style={{
                padding: "0 2%",
              }}
            >
              Social Media has been playing a significant role in the process of
              Search Engine Optimization. With the access usage of mobile phones
              and emerging digitalization, Social Media Platform proves really
              helpful to generate leads and traffic for your B2B business. If
              you are looking for SMM Service for your business then WebXeros
              Solutions can help you in boosting up your Brand awareness,
              increase website traffic, improving sales and creating a pipeline
              for your B2b business.
              <br />
              Our Core Understanding about the SMM can empower your business to
              make good networks, built up links with the potential buyers
              through sharing content, opinions, information, products via
              social channels like Twitter, Facebook, LinkedIn, Pinterest more.
              All marketing experts are quite dedicated to performing their job.
              Moreover, we always keep ourselves updated with the latest market
              trend. Social Media Marketing is quite important for the entire
              promotion of your website and website. It bridges the gap between
              the buyers and the product. If you are opting such service for the
              first thing then go through the below-mentioned points to know how
              is it helpful:
            </div>
          </div> */}

          {/* 00000000000000000000000 */}

          {/* UPDATED CONTENT START */}
          <div id="READMORE">
            {/* Social Media Marketing: */}
            <section style={{ margin: "0 5%" }}>
              <h3 style={styles.title}>Social Media Marketing:</h3>
              <div>
                <p style={styles.paraghrapText}>
                  Facebook, Twitter, Instagram, among several other social media
                  platforms have become such a vital part of people's lives that
                  it's no surprise that they can work quite well for businesses.
                  Today these networks are effective tools for our businesses,
                  and if you're not engaging on any of them, you're not worth
                  being in business!
                </p>
                <p style={styles.paraghrapText}>
                  Businesses are expanding globally facilitated by social media
                  platforms. You may simply interact with your clients and grow
                  your business with the aid of social media marketing. Our
                  services will assist you with all areas of your SMO plan,
                  including social media content creation, site management,
                  development, and social media marketing approach.
                </p>
                <p style={styles.paraghrapText}>
                  Draw Topic Solution is specialized in social media
                  optimization and has a lot of expertise with it. Our experts
                  are skilled in creating creative and appealing designs as well
                  as putting strategies into action to produce successful
                  designs for your business. When it comes to web-based
                  marketing firms, We are leaders. We provide transparency in
                  our activities so that our clients can see how our
                  professionals are working to grow their businesses. Get in
                  touch with us to Enjoy our classic SEO service.
                </p>
              </div>
            </section>
            {/* Need Of SMM: */}
            <section style={{ margin: "0 5%" }}>
              <h3 style={styles.title}>Need Of SMM:</h3>
              <div>
                <p style={styles.paraghrapText}>
                  It is essential to choose platforms that are regularly used by
                  customers in terms of reaching out to your target audience
                  more quickly. No other strategy can win so quickly like these
                  social media platforms, where most users spend significant
                  amounts of time frequently.
                </p>

                <h6 style={{ ...styles.title, textAlign: "left" }}>
                  Here are some of the reasons why social media marketing is
                  essential for your brand:
                </h6>
                <ul className="importance_ul">
                  <li>Targeted traffic is generated.</li>
                  <li>Increase Brand Awareness</li>
                  <li>Improves the SEO of your website.</li>
                  <li>Expand your reach.</li>
                  <li>Improve awareness of your target audience.</li>
                  <li>Maintain proper relations with employees.</li>
                  <li>
                    Targeting and retargeting are done with social media
                    advertisements.
                  </li>
                  <li>Integrate Social Media Posts to Opportunities.</li>
                  <li>Increase Brand Authenticity</li>
                  <li>Track Marketing Efforts.</li>
                  <li>Gives media attention.</li>
                  <li>
                    An active social media presence promotes brand loyalty.
                  </li>
                  <li>Increase your revenues.</li>
                  <li>Cost-Effective</li>
                  <li>Connects unfamiliar buyers to your brand</li>
                </ul>
                <p style={styles.paraghrapText}>
                  There is no doubt that social media marketing offers several
                  benefits for both startups and established companies.
                  Regularly updating the correct approach in this will result in
                  higher traffic, enhanced SEO, increased brand loyalty, a
                  healthier consumer experience, and much more. Every day many
                  competitions are increasing, so don't just sit and see them
                  and lose your potential clients. The sooner you begin, the
                  faster your business will flourish.
                </p>
              </div>
            </section>
            {/* WHY CHOOSE DRAW TOPIC: */}
            <section style={{ margin: "0 5%" }}>
              <h3 style={styles.title}>WHY CHOOSE DRAW TOPIC:</h3>
              <div>
                <p style={styles.paraghrapText}>
                  Our social media marketing team offers customized solutions
                  for your social media network demands. Whether you want to
                  increase the number of likes and followers on your web page,
                  or if you want to increase sales and earnings by using digital
                  services.
                </p>
                <h6 style={{ ...styles.title, textAlign: "left" }}>
                  The following are some of Draw Topic Solution's specific
                  social media market services for its customers.
                </h6>
                <ul className="importance_ul">
                  <li>
                    We handle day-to-day activities such as social media
                    campaigns, postings, content distribution, and more.
                  </li>
                  <li>
                    {`Raise a high level of engagement on Facebook, Twitter,
                    YouTube, Pinterest, and other well-known Social Media
                    Marketing & Advertising sites.`}
                  </li>
                  <li>
                    Create appealing videos and PPTs to influence the audience.
                  </li>
                  <li>Identifying and assessing a target audience.</li>
                  <li>
                    Creating and implementing unique and successful social media
                    marketing strategies.
                  </li>
                  <li>Blogs and corporate news are updated regularly.</li>
                  <li>Daily, monitor social media.</li>
                  <li>
                    An in-depth examination, observation, and adaptation of the
                    most latest developments.
                  </li>
                  <li>
                    Customers are being constantly reminded of the most updated
                    services.
                  </li>
                  <li>
                    Concentrating on certain keywords, subjects, and factors
                    that are related to your brands.
                  </li>
                  <li>
                    Appropriate application of development tools to achieve
                    desired results.
                  </li>
                </ul>

                <p style={styles.paraghrapText}>
                  So would you like to expand your brand's visibility and
                  establish a strong social media presence? Here we provide
                  social media marketing services at a very low cost. We design
                  credible brand profiles and pages for your company and use
                  social media to advertise your products and services. To raise
                  the engagement of your visitors and followers, Choose Draw
                  Topic Solution and get your branding done by us.
                </p>
              </div>
            </section>
          </div>
          {/* UPDATED CONTENT END */}

          {/* ------------------ */}
          {/* <div className="lower-text section-2">
            <p>Why choose Draw Topic ?</p>
            <article>
              WebXeros Solutions expert team always ascertain that you never
              make it to the list of “social media marketing fail websites” in
              fact we will carry out the campaign on the social media networks
              that will help you occupy the top position in the list social
              media marketing. Here are few points which make us the best:
            </article>
            <div className="container primary_text-sm">
              <div className="left">
                <ul>
                  <li>
                    We manage the Day-to-day tasks of social media campaigns,
                    posts, content sharing etc.
                  </li>
                  <li>
                    Write editorial social media content and also involved in
                    the press release
                  </li>
                  <li>
                    We are into Creating and uploading audiovisual content
                  </li>
                  <li>
                    Engage in conversations and answer questions in the relevant
                    groups and communities
                  </li>
                  <li>
                    Creating a social media editorial calendar and posting
                    schedule
                  </li>
                  <li>
                    Massive engagement in well-known Social Media Marketing &
                    Advertising sites is Facebook, Twitter, YouTube, Pinterest
                    etc.
                  </li>
                  <li>
                    Make your potential audience active through interesting and
                    engaging visual, content and information.
                  </li>
                </ul>
              </div>
              <div className="right">
                <ul>
                  <li>
                    Build network through Post and comment on relevant blogs
                  </li>
                  <li>
                    Our executive built an effective social profile without
                    missing and escaping any information.
                  </li>
                  <li>
                    Building social profiles and manage presence in social
                    networking sites
                  </li>
                  <li>
                    Influences the audience by creating attractive videos and
                    PPTs
                  </li>
                  <li>
                    Creating brand advocacy, Community outreach program as well
                    as Manage a blogger outreach program
                  </li>
                  <li>
                    Connect the audience with your business by providing the
                    right contact information.
                  </li>
                  <li>
                    Joining the relevant groups and community to connect with
                    the relevant audience
                  </li>
                </ul>
              </div>
            </div>
          </div> */}

          {/* ------------------ */}

          {/* <div className="lower-text imp-section primary_text-sm">
            <p>We provide “Result Oriented” SMM Services India</p>
            <div>
              The ultimate Respond of SMM service is too generic good and
              profitable leads for the business. This is possible if you have
              completely indulged yourself and making the efficient marketing of
              your business. Luckily, we are up for it. Our company is backed up
              the marketing experts, who are well-conscious about all the
              potential social media platforms and also with the tactics to
              target the genuine buys. They save their lots of time by not
              performing any unnecessary activity and focusing on drawing
              “result” through their quality work.
            </div>
          </div> */}

          {/* =================== */}

          {/* <div className="lower-text imp-section ">
            <p>Our SMM Process at Draw Topic Solutions</p>
            <div>
              <article>
                Before executing any works, we thoroughly strategies our
                actions. For that, we have divided the work in certain Category
                to make sure that each and every work is executing well and in a
                proper form. This practice also helps us in delivering precise
                work without any hassle. So go through the below-mentioned steps
                to know how we exactly work.
              </article>
              <ul className="imp-section__list">
                <li className="imp-section__list-item">
                  <div className="list_image-conatiner">
                    <img src="https://webxeros.com/wp-content/uploads/2019/05/marketing-analysis.jpg" />
                  </div>
                  <h4>Market Analysis</h4>
                  <div className="body">
                    Once we take custody of your project, we carefully do our
                    research work accordingly. In this, we understand the
                    nature, strengths, weaknesses, and threats of your business.
                    We find out the potential groups on social media and the
                    ideal place to do social media marketing for your brand.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <div className="list_image-conatiner">
                    <img src="https://webxeros.com/wp-content/uploads/2019/05/bigstock-Audience-written-in-search-bar-96675803-620x464.jpg" />
                  </div>
                  <h4>Identify the Audience</h4>
                  <div className="body">
                    After doing it, we identify the audience by knowing their
                    interests & tracking their social media activity. This helps
                    us in connecting with the right customers; maximize the
                    effectiveness of your marketing and saves lots of time. We
                    will reach to your potential audience at right time.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <div className="list_image-conatiner">
                    <img src="https://webxeros.com/wp-content/uploads/2019/05/shutterstock_130943402-300x262-1.jpg" />
                  </div>
                  <h4>Strategy Formulation</h4>
                  <div className="body">
                    Strategy is a must before executing any plan safely. So we
                    talked without marketing experts and find out the best
                    actions that should be performed to accomplish a certain
                    work. All the strategies are made while keeping in mind
                    about the new updates that have taken place in the market.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <div className="list_image-conatiner">
                    <img src="https://webxeros.com/wp-content/uploads/2019/05/socialmedia-posts-800x364.png" />
                  </div>
                  <h4>Social Media Posts</h4>
                  <div className="body">
                    Now, our team creates some catchy posts & shares them on
                    your social media channels to maximize the reach. We
                    regularly indulged ourselves in making the posts to stay
                    connected with your audience & providing them valuable
                    information regarding your business, product, and services.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <div className="list_image-conatiner">
                    <img src="https://webxeros.com/wp-content/uploads/2019/05/Part_5_-_SMM_Ads-1.png" />
                  </div>
                  <h4>Social Media Advertising</h4>
                  <div className="body">
                    Our social media advertising services will connect your
                    products and services to the potential customers who may be
                    interested in your business. Our social media marketing
                    experts will help you in achieving greater brand awareness
                    and obtaining more leads by targeting the right audience.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <div className="list_image-conatiner">
                    <img src="https://webxeros.com/wp-content/uploads/2019/05/images-1.jpg" />
                  </div>
                  <h4>The Monitoring</h4>
                  <div className="body">
                    It is quite essential to know whether we are actually
                    influencing your audience and generating leads through SMM
                    Service or not. So for that, we regularly monitor your
                    social media performance through many different tools like
                    Google analytics, Hootsuite, TweetReach, & Keyhole etc.
                  </div>
                </li>
              </ul>
            </div>
          </div> */}

          {/* =================== */}

          {/* ''''''''''''''''''''''''''''''''''' */}
          <div className="blocks-column col-lg-12 col-md-12 col-sm-12">
            <div className="title">
              <h3
                style={{
                  color: "#06092d",
                  fonSize: "2.2rem",
                  fontWeight: 800,
                  textAlign: "center",
                  padding: "0",
                  marginTop: "40px",
                  marginBottom: "30px",
                }}
              >
                Know Our SEO STRATEGY and Why It is the Best!
              </h3>
              <p className="primary_text" style={{ margin: "20px 10px" }}>
                Every brand requires social media marketing (SMO) to retain its
                popularity among the crowd since it allows businesses to
                approach people directly and convert them into consumers. By
                working with us, your company will receive a comprehensive set
                of SMO services, including a strong marketing strategy,
                appropriate channels, an engaged audience, engaging SMO wall
                postings, full report sharing, endorsements, and increased ROI
                on social campaigns.
              </p>
              <p className="primary_text" style={{ margin: "20px 10px" }}>
                Social Media Marketing Services may help your organization
                achieve new heights in the commercial world. Our one-of-a-kind
                social media marketing plan will deliver long-term success to
                your business.
              </p>
              <p className="primary_text" style={{ margin: "20px 10px" }}>
                As a Social Media Marketing Company, we make extensive use of
                social media platforms for marketing to increase brand
                visibility and audience engagement. Our staff consists of SMO
                professionals that will aid you in establishing a strong web
                presence.
              </p>
              <p className="primary_text" style={{ margin: "20px 10px" }}>
                We use a strategic social media campaign to publicize your
                business via all social media channels. To get your business
                expanding at breakneck speed. Our SMO Marketing Services are
                affordable and will undoubtedly revolutionize your industry.
              </p>
              <p className="primary_text" style={{ margin: "20px 10px" }}>
                The final result of SMM service is a general set of good and
                profitable leads for the business. This is accessible if you
                have completely indulged yourself while marketing your company
                effectively. Our organization is backed by marketing specialists
                that are well-versed in all relevant social media platforms as
                well as strategies for obtaining genuine customers. We avoid
                useless activities and only concentrate on producing "results"
                through our high-quality work.
              </p>
            </div>
            <div className="inner-column">
              <div className="row clearfix">
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/social-media"
                      >
                        Market Analysis:
                      </a>
                    </h4>
                    <div className="text">
                      When we take over your project, we rigorously do our
                      research to grasp the nature, strengths, limitations, and
                      risks of your organization. An analysis is important for
                      wellness checks for your social media activities. We offer
                      in-depth research and insights into your social media
                      marketing success, as well as important advice for channel
                      and profile optimization.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/pay-per-click"
                      >
                        Identify the Audience:
                      </a>
                    </h4>
                    <div className="text">
                      We identify the audience by discovering their interests
                      and tracking their social media activity. This allows us
                      to connect with the correct customers, enhance the impact
                      of your campaign, and save a significant amount of time.
                      To increase stability, our experts will help in
                      identifying guidelines and kits for your growth.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="#/content/email-marketing"
                      >
                        Strategy Formulation:
                      </a>
                    </h4>
                    <div className="text">
                      Before you carry out any plan, We first develop a
                      strategy. We determine each social media outlet for your
                      brand. Our marketing specialists choose the best
                      activities in a way to expand your social branding. All
                      tactics are developed while keeping the latest market
                      updates in mind.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/content-writing"
                      >
                        Social Media Post:
                      </a>
                    </h4>
                    <div className="text">
                      To enhance reach, our team prepares some engaging posts
                      and promotes them on your social media accounts. We are
                      routinely involved in creating posts to keep connected
                      with your audience and provide them with relevant
                      information about your company, products, and services.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/reputation-management"
                      >
                        Social Media Advertising:
                      </a>
                    </h4>
                    <div className="text">
                      Our social media advertising services will introduce your
                      products to potential clients who are interested in your
                      company. By targeting the relevant audience, our social
                      media marketing professionals will assist you in
                      increasing brand recognition and generating more leads.
                    </div>
                  </div>
                </div>

                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/conversion-optimization"
                      >
                        Monitoring:
                      </a>
                    </h4>
                    <div className="text">
                      You need to know whether or not we are enhancing the
                      audience and producing leads through SMM Service. To clear
                      the issue, we routinely check your social media
                      performance over many tools like Google Analytics,
                      Hootsuite, TweetReach, and Keyhole.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ''''''''''''''''''''''''''''''''''' */}

          {/* 00000000000000000000000 */}
        </div>
      </section>
      {/* End Benefit Section */}
    </div>
  );
}

export default SocialMedia;

const styles = {
  title: {
    color: "#06092d",
    fonSize: "2.1rem",
    fontWeight: 800,
    textAlign: "center",
    padding: "0",
    marginTop: "40px",
    marginBottom: "30px",
  },
  paraghrapText: {
    color: "#222",
    fontSize: "1.2rem",
  },
};
