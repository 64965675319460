export const SOCIAL_LINKS = {
  email: "hi@drawtopic.digital",
  instagram: "https://www.instagram.com/drawtopic.digital/",
  twitter: "https://twitter.com/drawtopic",
  linkedin: "https://www.linkedin.com/company/drawtopic/",
  facebook: "https://www.facebook.com/drawtopic.digital",
  phone_no: "99882-08206",
  address: `#14th 10th Floor, VR Punjab Mall Rd,
  Near Tdi Business Centre, Sector 118
  Sahibzada Ajit Singh Nagar, Punjab 160055`,
};
