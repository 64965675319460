import "../content.css";

import React, { useEffect, useState } from "react";
import pattern3 from "../../images/background/pattern-3.png";
import pattern4 from "../../images/background/pattern-4.png";
import pattern5 from "../../images/background/pattern-5.png";
import pattern6 from "../../images/background/pattern-6.png";
import benefit1 from "../../images/resource/benefit-1.jpg";
import benefit2 from "../../images/resource/benefit-2.png";
import benefit3 from "../../images/resource/benefit-3.jpg";
import cert from "../../images/resource/cert.png";
import cert1 from "../../images/resource/cert1.png";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Spinner from "../../components/spinner/Spinner";
const newLOGO = "new-logo-underline.png";

function ReputationManagement() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="main__content">
      {/* Benefit Section */}
      <section
        className="benefit-section"
        style={{
          padding: 0,
        }}
      >
        <div
          className="background-layer-one"
          style={{ backgroundImage: `url(${pattern5})` }}
        />
        <div
          className="background-layer-two"
          style={{ backgroundImage: `url(${pattern6})` }}
        />
        <div className="auto-container">
          <div className="row clearfix">
            {/* Images Column */}
            <div className="images-column col-lg-7 col-md-12 col-sm-12">
              <div
                style={{
                  // background: "red",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                }}
                className="inner-column"
              >
                <img
                  width="95%"
                  src={
                    "https://image.freepik.com/free-psd/laptop-iphone-digital-marketing-background_23-2148346689.jpg"
                  }
                />
                {/* <div
                  className="pattern-layer"
                  style={{ backgroundImage: `url(${pattern4})` }}
                />
                <div
                  className="pattern-layer-two"
                  style={{ backgroundImage: `url(${pattern3})` }}
                />
                <div className="color-layer" />
                <div className="image">
                  <img
                    src={
                      "https://image.freepik.com/free-psd/laptop-iphone-digital-marketing-background_23-2148346689.jpg"
                    }
                    alt
                  />
                </div>
                <div className="image-two">
                  <img
                    width="354px"
                    height="337px"
                    src={
                      "https://media-assets-02.thedrum.com/cache/images/thedrum-prod/s3-news-tmp-213062-hallam_digitalmarketing1--default--1280.jpg"
                    }
                    alt
                  />
                </div>
                <div className="image-three">
                  <img width="354px" height="337px" src={benefit2} alt />
                </div> */}
              </div>
            </div>
            {/* Content Column */}
            <div className="content-column col-lg-5 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="sec-title">
                  {/* <div className="title">Learn anything</div> */}
                  <h2 style={{ marginLeft: "10px" }}>
                    Online Reputation Management
                  </h2>
                </div>
                <ul className="list-style-one">
                  {/* <li><span className="icon flaticon-double-check" /><h3> {t('about')}: </h3> <strong style={{color:"#9e7d18"}}> TM7 SERVICES INC</strong><p>{t('TM7_description')}</p></li>
            <div className="btn-box">
            <a href="https://www.tm7.tech/"
                        target="_blank" className="theme-btn btn-style-two"><span className="txt">{t('find_out_button')} TM7 SERVICES INC</span></a>
          </div> */}
                  <li
                    style={{
                      padding: "20px 60px 0px 10px",
                    }}
                  >
                    <strong style={{ color: "#9e7d18" }}>
                      Online Reputation Management
                    </strong>
                    <p>
                      Content is the heart and soul of digital marketing. All of
                      your marketing efforts are useless without words. Because
                      words have the power to deliver your business message
                      successfully to the targeted audience. We don’t just focus
                      on improving your page ranking but also on creating the
                      buzz of your brand by delivering the right message to your
                      targeted audience. We offer all types of content writing
                      services whether it is an article, blogs, Guest posts,
                      Press Releases, Infographic content, website content and
                      so on. We cater to all your content requirements from
                      small to bulk orders. We provide a diverse range of
                      content writing solutions in India to meet all your
                      specific needs.
                    </p>
                  </li>
                  <div className="btn-box">
                    <a
                      href="#read"
                      // target="_blank"
                      className="theme-btn btn-style-two"
                    >
                      <span className="txt">Read More...</span>
                    </a>
                  </div>
                </ul>
              </div>
            </div>
          </div>
          {/* Lower Text */}
          <div id="read" className="lower-text primary_text-sm">
            <p>
              Online Reputation Management? <br />
              {/* Contact us to work with a results-driven digital marketing agency */}
            </p>
            <div
              style={{
                padding: "0 2%",
              }}
            >
              Content is the heart and soul of digital marketing. All of your
              marketing efforts are useless without words. Because words have
              the power to deliver your business message successfully to the
              targeted audience. We don’t just focus on improving your page
              ranking but also on creating the buzz of your brand by delivering
              the right message to your targeted audience. We offer all types of
              content writing services whether it is an article, blogs, Guest
              posts, Press Releases, Infographic content, website content and so
              on. We cater to all your content requirements from small to bulk
              orders. We provide a diverse range of content writing solutions in
              India to meet all your specific needs.
              <br />
              At WebXeros Solutions, we have a team of content writers & editors
              in India who are highly skilled, qualified and professional. Our
              content writers can handle all your content requirements with
              perfection. We believe in using simple and frequently used words
              so that it is easy-to-interpret for your readers. We develop 100%
              unique, fresh, creative and relevant content. We have expertise in
              creating SEO optimized content to improve your organic traffic and
              results. We offer customized pricing plus special discounts on
              bulk orders. Why You Need Content Writing for Your Business? The
              key to successful marketing is powerful content. Content writing
              helps to drive more traffic, improve SEO results and keep users
              engaged with the website. This is one of the best ways to
              communicate effectively with your potential audience. Frequently
              posting content on your website and other marketing channels lets
              people know what your business is about. Here are some benefits of
              content writing for your business.
              <br />
              <hr />
              i) Strengthen your brand
              <br />
              ii) Convey the right message to your targeted audience
              <br />
              iii) Drive more organic search traffic
              <br />
              iv) Engage the readers with website
              <br />
              v) A good content can earn links from reputed websites
              <br />
              vi) Great content is always shared
              <br />
              vii) Helps to rank your website higher in all Search Engines,
              especially in Google
            </div>
          </div>
        </div>
      </section>
      {/* End Benefit Section */}
    </div>
  );
}

export default ReputationManagement;
