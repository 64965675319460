import React from "react";
import bg1 from "../../images/background/1.png";
import pattern1 from "../../images/background/pattern-1.png";
import pattern2 from "../../images/background/pattern-2.png";
import instructor from "../../images/resource/instructor.png";
import icon1 from "../../images/icons/1.svg";
import icon2 from "../../images/icons/2.png";
import icon3 from "../../images/icons/3.png";
import icon4 from "../../images/icons/4.png";
// import icon5 from "../../images/icons/5.png";
import icon6 from "../../images/icons/6.png";
import icon7 from "../../images/icons/7.png";
import icon8 from "../../images/icons/8.png";
import icon9 from "../../images/icons/9.png";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

import icon5 from "../../assets/icon/orm.png";
import { Link } from "react-router-dom";

const Features = () => {
  const { t } = useTranslation();
  return (
    <>
      {/* Instructor Section */}
      <section className="instructor-section">
        <div
          className="background-layer"
          style={{ backgroundImage: `url(${bg1})` }}
        />
        <div
          className="background-layer-one"
          style={{ backgroundImage: `url(${pattern1})` }}
        />
        <div
          className="background-layer-two"
          style={{ backgroundImage: `url(${pattern2})` }}
        />
        <div className="auto-container">
          <div className="row clearfix">
            {/* Blocks Column */}
            <div className="blocks-column col-lg-8 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="row clearfix">
                  {/* Service Block */}
                  <div className="service-block col-lg-6 col-md-6 col-sm-12">
                    <div
                      className="inner-box wow fadeInLeft"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="border-layer" />
                      <div className="icon-box">
                        <img src={icon1} />
                      </div>
                      <h4>
                        <Link to="/content/search-engine-optimization">
                          SEARCH ENGINE OPTIMIZATION – SEO
                        </Link>
                      </h4>
                      <div className="text">
                        Through careful keyword research and white hat
                        practices, we can help you achieve high rankings in the
                        major search engines.
                      </div>
                    </div>
                  </div>
                  {/* Service Block */}
                  <div className="service-block col-lg-6 col-md-6 col-sm-12">
                    <div
                      className="inner-box wow fadeInLeft"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="border-layer" />
                      <div className="icon-box">
                        <img src={icon2} />
                      </div>
                      <h4>
                        <Link to="/content/local-SEO">LOCAL SEO </Link>
                      </h4>
                      <div className="text">
                        Local search engine optimization is a branch of SEO that
                        focuses on optimizing a website to be found in local
                        search results. Content, on-page optimizations, and link
                        building all with a focused, localized intent are part
                        of these efforts.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Instructor Column */}
            <div className="instructor-column col-lg-4 col-md-12 col-sm-12">
              <div
                className="inner-column wow fadeInRight"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <h2>Services</h2>
                {/* <p>jgkkk</p> */}
                {/* <a className="click-here" href="membership.html">{t('cick_here')}</a> */}
                <div className="image titlt" data-tilt data-tilt-max={4}>
                  <img src={instructor} alt />
                </div>
              </div>
            </div>

            <div className="blocks-column col-lg-12 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="row clearfix">
                  {/* Service Block */}
                  <div className="service-block col-lg-4 col-md-6 col-sm-12">
                    <div
                      className="inner-box wow fadeInLeft"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="border-layer" />
                      <div className="icon-box">
                        <img src={icon3} />
                      </div>
                      <h4>
                        <Link to="/content/social-media">
                          SOCIAL MEDIA MARKETING
                        </Link>
                      </h4>
                      <div className="text">
                        Social media marketing is a critical element in staying
                        connected with your audience. Working independently of,
                        or side-by-side with you, we help keep your social
                        community engaged.
                      </div>
                    </div>
                  </div>
                  {/* Service Block */}
                  <div className="service-block col-lg-4 col-md-6 col-sm-12">
                    <div
                      className="inner-box wow fadeInLeft"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="border-layer" />
                      <div className="icon-box">
                        <img src={icon4} />
                      </div>
                      <h4>
                        <Link to="/content/pay-per-click">
                          PAY PER CLICK – PPC
                        </Link>
                      </h4>
                      <div className="text">
                        Pay-per-click (PPC) is an internet advertising model
                        used to drive traffic to websites, in which an
                        advertiser pays a publisher (typically a search engine,
                        website owner, or a network of websites) when the ad is
                        clicked
                      </div>
                    </div>
                  </div>
                  {/* Service Block */}
                  <div className="service-block col-lg-4 col-md-6 col-sm-12">
                    <div
                      className="inner-box wow fadeInLeft"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="border-layer" />
                      <div className="icon-box">
                        <img src={icon5} />
                      </div>
                      <h4>
                        <Link to="/content/email-marketing">
                          EMAIL MARKETING
                        </Link>
                      </h4>
                      <div className="text">
                        Email marketing is the act of sending a commercial
                        message, typically to a group of people, using email. In
                        its broadest sense, every email sent to a potential or
                        current customer could be considered email marketing. It
                        involves using email to send advertisements, request
                        business, or solicit sales or donations.
                      </div>
                    </div>
                  </div>
                  {/* Service Block */}
                  <div className="service-block col-lg-4 col-md-6 col-sm-12">
                    <div
                      className="inner-box wow fadeInLeft"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="border-layer" />
                      <div className="icon-box">
                        <img src={icon6} />
                      </div>
                      <h4>
                        <Link to="/content/content-writing">
                          CONTENT WRITING
                        </Link>
                      </h4>
                      <div className="text">
                        Content writing is the process of planning, writing and
                        editing web content, typically for digital marketing
                        purposes. It can include writing blog posts and
                        articles, scripts for videos and podcasts, as well as
                        content for specific platforms, such as tweetstorms on
                        Twitter or text posts on Reddit.
                      </div>
                    </div>
                  </div>
                  {/* Service Block */}
                  <div className="service-block col-lg-4 col-md-6 col-sm-12">
                    <div
                      className="inner-box wow fadeInLeft"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="border-layer" />
                      <div className="icon-box">
                        <img src={icon7} />
                      </div>
                      <h4>
                        <Link to="/content/reputation-management">
                          ONLINE REPUTATION MANAGEMENT
                        </Link>
                      </h4>
                      <div className="text">
                        Online reputation management (ORM) is the practice of
                        crafting strategies that shape or influence the public
                        perception of an organization, individual or other
                        entity on the Internet. It helps drive public opinion
                        about a business and its products and services.
                      </div>
                    </div>
                  </div>

                  {/* Service Block */}
                  <div className="service-block col-lg-4 col-md-6 col-sm-12">
                    <div
                      className="inner-box wow fadeInLeft"
                      data-wow-delay="0ms"
                      data-wow-duration="1500ms"
                    >
                      <div className="border-layer" />
                      <div className="icon-box">
                        <img src={icon8} />
                      </div>
                      <h4>
                        <Link to="/content/conversion-optimization">
                          CONVERSION OPTIMIZATION
                        </Link>
                      </h4>
                      <div className="text">
                        Conversion rate optimization (CRO) is the practice of
                        increasing the percentage of users who perform a desired
                        action on a website. Desired actions can include
                        purchasing a product, clicking 'add to cart', signing up
                        for a service, filling out a form, or clicking on a link
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Features;
