import "../content.css";

import React, { useEffect, useState } from "react";
import pattern3 from "../../images/background/pattern-3.png";
import pattern4 from "../../images/background/pattern-4.png";
import pattern5 from "../../images/background/pattern-5.png";
import pattern6 from "../../images/background/pattern-6.png";
import benefit1 from "../../images/resource/benefit-1.jpg";
import benefit2 from "../../images/resource/benefit-2.png";
import benefit3 from "../../images/resource/benefit-3.jpg";
import cert from "../../images/resource/cert.png";
import cert1 from "../../images/resource/cert1.png";
import "./seo.css";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Spinner from "../../components/spinner/Spinner";

const newLOGO = "new-logo-underline.png";
function SEO() {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="main__content">
      {/* Benefit Section */}
      <section
        className="benefit-section"
        style={{
          padding: 0,
        }}
      >
        <div
          className="background-layer-one"
          style={{ backgroundImage: `url(${pattern5})` }}
        />
        <div
          className="background-layer-two"
          style={{ backgroundImage: `url(${pattern6})` }}
        />
        <div className="auto-container">
          <div className="row clearfix">
            {/* Images Column */}
            <div className="images-column col-lg-7 col-md-12 col-sm-12">
              <div
                style={{
                  // background: "red",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                }}
                className="inner-column"
              >
                <img
                  width="95%"
                  src={
                    "https://image.freepik.com/free-psd/laptop-iphone-digital-marketing-background_23-2148346689.jpg"
                  }
                />
                {/* <div
                  className="pattern-layer"
                  style={{ backgroundImage: `url(${pattern4})` }}
                />
                <div
                  className="pattern-layer-two"
                  style={{ backgroundImage: `url(${pattern3})` }}
                />
                <div className="color-layer" />
                <div className="image">
                  <img
                    src={
                      "https://image.freepik.com/free-psd/laptop-iphone-digital-marketing-background_23-2148346689.jpg"
                    }
                    alt
                  />
                </div>
                <div className="image-two">
                  <img
                    width="354px"
                    height="337px"
                    src={
                      "https://media-assets-02.thedrum.com/cache/images/thedrum-prod/s3-news-tmp-213062-hallam_digitalmarketing1--default--1280.jpg"
                    }
                    alt
                  />
                </div>
                <div className="image-three">
                  <img width="354px" height="337px" src={benefit2} alt />
                </div> */}
              </div>
            </div>
            {/* Content Column */}
            <div className="content-column col-lg-5 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="sec-title">
                  {/* <div className="title">Learn anything</div> */}
                  <h2 style={{ marginLeft: "10px" }}>
                    Search Engine Optimization
                  </h2>
                </div>
                <ul className="list-style-one">
                  {/* <li><span className="icon flaticon-double-check" /><h3> {t('about')}: </h3> <strong style={{color:"#9e7d18"}}> TM7 SERVICES INC</strong><p>{t('TM7_description')}</p></li>
            <div className="btn-box">
            <a href="https://www.tm7.tech/"
                        target="_blank" className="theme-btn btn-style-two"><span className="txt">{t('find_out_button')} TM7 SERVICES INC</span></a>
          </div> */}
                  <li
                    style={{
                      padding: "20px 60px 0px 10px",
                    }}
                  >
                    <strong style={{ color: "#9e7d18" }}>
                      Search Engine Optimization
                    </strong>
                    <p>
                      The formula for making your business digitally happy is
                      Getting more traffic, to get more revenue. So the method
                      that generates organic traffic and leads to your website
                      is Search Engine Optimization (SEO). Draw Topic Solution
                      provides one-of-a-kind SEO services to help your company
                      or business by listing it on top. Our goal is directly
                      proportional to our client goal, which means we understand
                      that our progress depends on our customer’s success. As a
                      result, we conduct regular analysis on the latest trends
                      and strategies in the Digital Marketing Industry to keep
                      our SEO service up to date.
                    </p>
                  </li>
                  <div className="btn-box">
                    <a href="#READMORE" className="theme-btn btn-style-two">
                      <span className="txt">Read More...</span>
                    </a>
                  </div>
                </ul>
              </div>
            </div>
          </div>
          {/* Lower Text */}
          {/* <div className="lower-text">
            <p>
              Search Engine Optimization? <br />
              {/* Contact us to work with a results-driven digital marketing agency */}
          {/* </p>
            <div
              style={{
                padding: "0 2%",
              }}
            >
              Search Engine Optimization has become a new way of marketing that
              improves your business sales and let you connect with the
              potential buyers. In this digital World, taking SEO services India
              for presenting your business online is essential. To accomplish
              all your company’s goal, WebXeros Solutions has bought up the
              One-stop-platform for all your digital marketing needs and
              providing the Best SEO services India. Our experienced SEO
              professionals plan effective strategies to generate maximum leads
              for your website along with revenue returns. * */}
          {/* <br />
              In our SEO Team, we included creative writers, designers, SEO
              Analyst, and developers, which are highly experienced and trained
              to boost your ranking with result-driven approach. Google is an
              ever changing platform that frequently changes its ranking
              algorithm. To ensures the updated SEO services India, our team
              stays updated with the latest market trend to broaden your traffic
              in an efficient manner. Our primary objective is to rank your
              website on the first page. So all the SEO strategies applies
              accordingly.
              <br />
              So fulfill your business requirements and get noticed by a buyer
              with Top SEO Company India “WebXeros Solutions” and give a quick
              start to your business and sales.
              <br />
              Why SEO is Important for Your Business? Search Engine Optimization
              can significantly boost up your business sales and generate leads.
              If you want to grab the attention of the market and make sure you
              have opted for the best SEO Service with the top SEO Company India
              like “WebXeros Solutions”. No matters, in which business segment
              you are dealing in, with the help of effective SEO Service, you
              can easily make a place in the market. SEO enables your business
              to become easily reachable and connect with the potential buyers,
              which is the ultimate goal of every business. It enables you the
              following benefits: Leads-Businesses are completely based on
              goals. Some company has the goal to either generate profit by
              selling product and service and some has to get audience traffic.
              With the right kind of Optimization, keyword placement, regular
              interaction with customers, sharing etc in Search Engine
              Optimization, your website starts getting leads. After a
              speculated time frame, your website starts appearing on the 1st
              page of Search Engine. */}
          {/* </div>
          </div> */}

          {/* -------------- */}

          {/* ----------------- UPDATED CONTENT START  ------------------------- */}
          <div id="READMORE">
            {/* NEED OF SEO */}
            <section style={{ margin: "0 5%" }}>
              <h3 style={styles.title}>Need Of SEO:</h3>
              <div>
                <p style={styles.paraghrapText}>
                  <h6 style={{ ...styles.title, textAlign: "left" }}>
                    To Increase the Brand Value, To Get The More Visitors and
                    Top Ranking At The Search Engine Result, - You Need SEO
                  </h6>
                  SEO helps in growing your businesses digitally to achieve high
                  internet visibility, resulting in generating a higher volume
                  of traffic over your website.
                </p>
                <p style={styles.paraghrapText}>
                  <h6 style={{ ...styles.title, textAlign: "left" }}>
                    When you search for something-
                  </h6>
                  Just think, In your everyday life when you need any
                  information or services what you do, how you usually search.
                  You just google it and scroll the results and select the
                  service that best suited you. These results are based on the
                  keywords that you had mentioned while searching, which means
                  Google understands the language of keywords. And When you
                  Scroll for selecting services, How long do you keep going down
                  or changing pages?
                </p>
                <p style={styles.paraghrapText}>
                  <h6 style={{ ...styles.title, textAlign: "left" }}>
                    So, we can conclude from here is-
                  </h6>
                  We hardly switch pages and rely on those websites that come
                  after the 1st page. So it means a healthy website is listed on
                  the top page of the website. Being the best SEO experts, Draw
                  Topic Solution covers all the requirements of SEO so that if
                  someone searches anything that relates to your brand, Your
                  website gets a door for reaching first into the search engine
                  result. The significance of SEO services cannot be avoided; as
                  it is a strong internet marketing platform that aids in
                  competing with millions of other websites online and
                  positioning your website at the top of search engine rankings.
                  SEO empowers your brand to become more easily accessible and
                  reach out to potential customers, which is the primary
                  objective of every business.
                </p>
                <h6 style={{ ...styles.title, textAlign: "left" }}>
                  Here are the 3 strategies of Search Engine Optimization. As a
                  business owner, you should know which strategy is appropriate
                  for your online marketing:
                </h6>
              </div>
            </section>
            {/* WHY CHOOSE DRAW TOPIC: */}
            <section style={{ margin: "0 5%" }}>
              <h3 style={styles.title}>WHY CHOOSE DRAW TOPIC:</h3>
              <div>
                <p style={styles.paraghrapText}>
                  When it comes to achieving your marketing objectives, the
                  right marketing strategy is required, which is why you need a
                  team of experts to develop the best and effective marketing
                  strategy for your business.
                  <br /> We completely focus on your website, optimizing all of
                  its aspects and managing its ranking. The tasks included, but
                  are not restricted to:
                </p>

                <ul className="importance_ul">
                  <li>Comprehensive SEO advice and goal-setting</li>
                  <li>Audits and analysis of the site</li>
                  <li>Research of keywords</li>
                  <li>Title tags and meta tags optimization</li>
                  <li>Cleaning up the HTML code</li>
                  <li>Back Linking</li>
                  <li>Design, implementation, and management consultation</li>
                  <li>
                    Submissions to search engines and directories on a manual
                    basis
                  </li>
                  <li>Reporting on performance regularly</li>
                  <li>
                    Troubleshooting difficulties with rankings and traffic
                  </li>
                  <li>and more that you need…</li>
                </ul>

                <p style={styles.paraghrapText}>
                  To employ a dedicated SEO specialist in India, contact Draw
                  Topic Solution immediately. We can tailor a package and price
                  that fits your budget, needs, and objectives.
                </p>
              </div>
            </section>
          </div>
          {/* ----------------- UPDATED CONTENT END  ------------------------- */}

          {/* <div className="lower-text imp-section">
            <p>Why SEO is Important for Your Business?</p>
            <div>
              <article>
                Search Engine Optimization can significantly boost up your
                business sales and generate leads. If you want to grab the
                attention of the market and make sure you have opted for the
                best SEO Service with the top SEO Company India like “WebXeros
                Solutions”. No matters, in which business segment you are
                dealing in, with the help of effective SEO Service, you can
                easily make a place in the market. SEO enables your business to
                become easily reachable and connect with the potential buyers,
                which is the ultimate goal of every business. It enables you the
                following benefits:
              </article>
              <ul className="imp-section__list">
                <li className="imp-section__list-item">
                  <div className="list_image-conatiner">
                    <img src="https://webxeros.com/wp-content/uploads/2019/03/leads-800x800.jpg" />
                  </div>
                  <h4>Leads</h4>
                  <div className="body">
                    Businesses are completely based on goals. Some company has
                    the goal to either generate profit by selling product and
                    service and some has to get audience traffic. With the right
                    kind of Optimization, keyword placement, regular interaction
                    with customers, sharing etc in Search Engine Optimization,
                    your website starts getting leads. After a speculated time
                    frame, your website starts appearing on the 1st page of
                    Search Engine
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <div className="list_image-conatiner">
                    <img src="https://webxeros.com/wp-content/uploads/2019/03/traffic-1-800x759.jpg" />
                  </div>
                  <h4>Traffic</h4>
                  <div className="body">
                    If you are online visibility is good and the appealing one
                    then the chances of getting traffic on your website become
                    high. The Traffic can be generated through the regular
                    involvement of your digital appearance, which is possible
                    through the best SEO Procedure. It enables you to generate
                    traffic through the right kind of optimization and keyword
                    placements on your website.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <div className="list_image-conatiner">
                    <img src="https://webxeros.com/wp-content/uploads/2019/03/revenue-1.jpg" />
                  </div>
                  <h4>Revenue</h4>
                  <div className="body">
                    When your website starts getting Traffic and leads then
                    nobody can stop you to generate maximum revenue return for
                    your Company. It let you enjoy the daily inquiries, sales,
                    and the attention of customers, which will ultimately
                    convert into maximum revenue return. Everyone starts a
                    business with a profit mindset and SEO Brings you closer
                    toward your goal and provides you the best and appealing
                    demonstration in the relevant market only.
                  </div>
                </li>
              </ul>
            </div>
          </div> */}

          {/* ------------------ */}
          {/* <div className="lower-text section-2">
            <p>Why choose Draw Topic ?</p>
            <div className="container">
              <div className="left primary_text-sm">
                By continuously receiving the quality feedback from our
                customers, we have become India’s Best SEO Company India. There
                are lots of factors that made us the prominent Company amongst
                all such as:
                <ul>
                  <li>We Provide Result Oriented SEO Service </li>
                  <li>Monthly Report to track the website position </li>
                  <li>Create engaging Data for your websites </li>
                  <li>Have In-depth SEO Knowledge </li>
                  <li>Cost-effective SEO Service India </li>
                  <li>
                    Apply the Proven SEO States to bring the best results{" "}
                  </li>
                  <li>Guaranteed Traffic a speculated time frame </li>
                  <li>24×7 Customers Supports </li>
                </ul>
              </div>
              <div className="right">
                <img src="https://webxeros.com/wp-content/uploads/2019/03/seo-1.jpg" />
              </div>
            </div>
          </div> */}
          {/* ------------------ */}

          <div className="lower-text imp-section primary_text-sm">
            <p>Know Our SEO STRATEGY and Why It is the Best!</p>
            <div>
              <b>“Draw Topic Solution”</b> is a team of SEO Experts who will
              always respond to these developments and keep your website at the
              top of search engine results pages (SERPs). We include everything
              you require and more fast inquiry response, specialized project
              management, and ongoing contact. Having the best SEO professional
              on your team may greatly contribute to your online marketing
              performance.
            </div>
          </div>

          {/* =================== */}

          {/* <div className="lower-text imp-section">
            <p>Our Specialized Services</p>
            <div>
              <ul className="imp-section__list">
                <li className="imp-section__list-item">
                  <div className="list_image-conatiner">
                    <img src="https://webxeros.com/wp-content/uploads/2019/05/images.jpg" />
                  </div>
                  <h4>Keyword Research</h4>
                  <div className="body">
                    Our company follows the finely tuned keyword research
                    process, which helps us in optimizing your website for
                    mostly search keywords and phrases on Google. Our effective
                    Keyword research enables you to meets the maximum customers,
                    who are looking for the relevant business that you are
                    offering. This is one of the initial steps of SEO and we
                    focused on it to bring the right kind of business for your
                    website.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <div className="list_image-conatiner">
                    <img src="https://webxeros.com/wp-content/uploads/2019/05/514122-1bRw321487745975.jpg" />
                  </div>
                  <h4>Website SEO Audit</h4>
                  <div className="body">
                    We make use of the most effective SEO used tools such as
                    screemingfrog, woorank, semrush, moz, SEOsitecheckup and
                    many others for the website analysis. Our SEO Professionals
                    make use of these tools to find out the requirements of the
                    website and optimize it accordingly. We practice these tools
                    to find out un-optimized on-page factors and to find out
                    whether the website has been crawled and indexed or not.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <div className="list_image-conatiner">
                    <img src="https://webxeros.com/wp-content/uploads/2019/05/Link-Building2.jpg" />
                  </div>
                  <h4>Link Building</h4>
                  <div className="body">
                    Building up backlinks is one of the major factors that work
                    effectively in the entire SEO procedure. If it is done
                    correctly then you could get the steady ranks over search
                    engine and on the other hand, it may also give you adverse
                    impacts that may cost your website. At Draw Topic Solutions,
                    we build up quality backlinks for your website that directly
                    reflects on your website ranking and drive traffic.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <div className="list_image-conatiner">
                    <img src="https://webxeros.com/wp-content/uploads/2019/05/0_XBONPFdMjVtkW8ul.jpg" />
                  </div>
                  <h4>Content Marketing</h4>
                  <div className="body">
                    Content is the key for Search Engine Optimization. Our SEO
                    Team consists of tremendously creative and potential
                    writers, who write quality content while giving priority to
                    the keywords and targeted phrases. Their valuable
                    information through content helps us in generating
                    customers’ attention as well as leads. We will create,
                    publish, and distribute relevant content to get the
                    attention of your audience.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <div className="list_image-conatiner">
                    <img src="https://webxeros.com/wp-content/uploads/2019/05/social-media.jpg" />
                  </div>
                  <h4>Social Media Promotion</h4>
                  <div className="body">
                    SMM is an effective approach to reach and target the right
                    customers. We give prime focus on Social Media campaigns and
                    creatively designed them to help brands get visibility and
                    improve their sales. We target all the potential social
                    media channels including Facebook, Twitter, LinkedIn and
                    more. We will create and publish content on your social
                    media channels. We will help to achieve your business goals.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <div className="list_image-conatiner">
                    <img src="https://webxeros.com/wp-content/uploads/2019/05/reporting.jpg" />
                  </div>
                  <h4>{"Reporting & Analyzing"}</h4>
                  <div className="body">
                    To find out the result of our SEO Campaign, we do the
                    in-depth analysis of data to understand which SEO Strategy
                    is working for your website and which not. Through this
                    analysis, we also provide you the monthly report stating,
                    how much queries are being generated, conversion rates,
                    analytics, and visibility. Basically, we record and provide
                    you the difference in every month so that you can do a
                    comparison.
                  </div>
                </li>
              </ul>
            </div>
          </div> */}
          {/* =================== */}

          {/* ''''''''''''''''''''''''''''''''''' */}
          <div
            id="read"
            className="blocks-column col-lg-12 col-md-12 col-sm-12"
          >
            <div className="title">
              <h3
                style={{
                  color: "#06092d",
                  fonSize: "2.2rem",
                  fontWeight: 800,
                  textAlign: "center",
                  padding: "0",
                  marginTop: "40px",
                  marginBottom: "70px",
                }}
              >
                OUR SPECIALIZED SERVICE
              </h3>
            </div>
            <div className="inner-column">
              <div className="row clearfix">
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        href="#"
                        onClick={(e) => e.preventDefault()}
                        //  href="/content/social-media"
                      >
                        KEYWORD RESEARCH:
                      </a>
                    </h4>
                    <div className="text">
                      Our firm uses a highly honed keyword research technique
                      that assists us in optimizing your website for the
                      majority of Google search keywords and phrases. Our
                      excellent keyword research allows you to reach the widest
                      possible audience of clients who are seeking the services
                      you provide. The baseline for SEO is keyword research. It
                      improves the efficiency of the entire process that helps
                      your website to get the correct sort of traffic.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        href="#"
                        onClick={(e) => e.preventDefault()}
                        // href="/content/pay-per-click"
                      >
                        WEBSITE SEO AUDIT:
                      </a>
                    </h4>
                    <div className="text">
                      We analyze your website thoroughly, dedicating hundreds of
                      hours to technical examinations and SEO audits. SEO audits
                      are a vital and required part of maintaining your SEO
                      strategy. If your website isn't properly optimized for
                      search, you're losing out on a lot of traffic and
                      attention from potential customers. An SEO audit requires
                      to be done regularly for the long-term success of your
                      website’s performance. We employ the most proven Seo tools
                      available, like screaming frog, woorank, semrush, Moz,
                      SEOsitec, and many more.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        href="#"
                        onClick={(e) => e.preventDefault()}
                        // href="#/content/email-marketing"
                      >
                        {/* VIDEO PRODUCTION  */}
                        LINK BUILDING:
                      </a>
                    </h4>
                    <div className="text">
                      Building backlinks is one of the most important aspects of
                      the SEO process. If carried out effectively, you may get
                      consistent search engine rankings. We at Draw Topic
                      Solutions develop high-quality backlinks for your website,
                      which have a direct impact on its ranking and traffic. Our
                      SEO professionals help your organizations in making a
                      connection with numerous backlinks that enables many
                      business opportunities, allowing you to climb the search
                      results ladder.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        href="#"
                        onClick={(e) => e.preventDefault()}
                        // href="/content/content-writing"
                      >
                        CONTENT MARKETING:
                      </a>
                    </h4>
                    <div className="text">
                      All marketing actions that focus on the generation and
                      distribution of information are referred to as content
                      marketing. It should undoubtedly be a strategy of your SEO
                      plan if you're serious about it. SEO is much like a sealed
                      empty container without valuable content. So here we must
                      ensure that every keyword, phrase, article, and other
                      piece of content that we create makes sense to get the
                      target audience. grab your audience's attention.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        href="#"
                        onClick={(e) => e.preventDefault()}
                        // href="/content/reputation-management"
                      >
                        SOCIAL MEDIA PROMOTION:
                      </a>
                    </h4>
                    <div className="text">
                      For firms of all sizes, social media marketing is a great
                      way to reach out to prospects and clients. Great social
                      media marketing may help your organization reach
                      remarkable results by building devoted brand supporters
                      and driving leads and revenue. Draw Topic Solution gives
                      prime focus over all these tactics to grab complete
                      visibility and promotion. We pride ourselves on taking a
                      comprehensive approach to problem-solving, analyzing every
                      single component down to the smallest detail.
                    </div>
                  </div>
                </div>

                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        href="#"
                        onClick={(e) => e.preventDefault()}
                        // href="/content/conversion-optimization"
                      >
                        REPORTING AND ANALYZING:
                      </a>
                    </h4>
                    <div className="text">
                      One of the most difficult tasks for SEO specialists is not
                      just to create content that ranks or generates traffic,
                      but also to be able to display the results of your
                      approach in precise reports. A good report should, of
                      course, not only reflect the present state and the
                      produced outcomes but also give insights into the future
                      steps to be taken. Draw Topic Solution recognizes every
                      metric that should be taken into account while making this
                      report.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ''''''''''''''''''''''''''''''''''' */}
        </div>
      </section>
      {/* End Benefit Section */}
    </div>
  );
}

export default SEO;

const styles = {
  title: {
    color: "#06092d",
    fonSize: "2.1rem",
    fontWeight: 800,
    textAlign: "center",
    padding: "0",
    marginTop: "40px",
    marginBottom: "30px",
  },
  paraghrapText: {
    color: "#222",
    fontSize: "1.2rem",
  },
};
