import React, { useEffect, useRef } from "react";
import logo from "../../assets/logo/logo.png";
import "../../components/header/header.css";
import { NavLink, Link, useHistory } from "react-router-dom";
import {
  ABOUT_US,
  CONTACT_US,
  FEATURES,
  HOME,
} from "../../constants/routePaths";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import classNames from "classnames";
import Features from "../../views/features/Features";
import Contact from "../../views/contact/Contact";
import About from "../../views/about/About";
import Demo from "../../views/demo/Demo";
import Pricing from "../../views/pricing/Pricing";
import Home from "../../views/home/Home";
import Faq from "../../views/faq/Faq";
import { SOCIAL_LINKS } from "../../constants/socialLinks";
import TrueHeader from "../TrueHeader";

const languages = [
  {
    code: "sp",
    name: "spanish",
    country_code: "es",
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
];

const GlobeIcon = ({ width = 24, height = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="currentColor"
    className="bi bi-globe"
    viewBox="0 0 16 16"
  >
    <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
  </svg>
);

const newLOGO = "new-logo-underline.png";
const smallLogo = "smalllogo.png";

const Header = () => {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  const megaMenuRef = useRef(null);
  const myMobileNavRef = useRef(null);
  const subMenuRef = useRef(null);

  const history = useHistory(null);

  useEffect(() => {
    console.log("Setting page stuff");
    document.body.dir = currentLanguage.dir || "ltr";
  }, [currentLanguage, t]);

  const closeNav = () => {
    myMobileNavRef.current.style.width = "0";
  };
  const openNav = () => {
    myMobileNavRef.current.style.width = "300px";
  };

  const handleSubLink = (toggle) => {
    if (toggle === "toggle") {
      subMenuRef.current.classList.toggle("hide");
    }
    if (toggle === "hide") {
      console.log("hide", subMenuRef.current);
      subMenuRef.current.classList.add("hide");
    }
  };

  const handleNavLinkClick = () => {
    megaMenuRef.current.style.opacity = "0";
    megaMenuRef.current.style.transform = "scaleY(0)";
  };

  const handleNavLinkOpen = () => {
    megaMenuRef.current.style.opacity = "1";
    megaMenuRef.current.style.transform = "scaleY(1)";
  };

  return (
    <>
      {/* Main Header*/}
      <header style={{ position: "sticky", top: "0" }} className="main-header">
        {/*Header-Upper*/}
        <div className="header-upper">
          <div className="outer-container clearfix header__conatiner">
            {/* <div className="pull-left logo-box"> */}
            <div className="logo">
              <Link exact to="/">
                <img
                  style={{ marginTop: "100px" }}
                  height="100px"
                  width="300px"
                  src={newLOGO}
                  alt
                  title
                />
              </Link>
            </div>
            {/* </div> */}

            <span className="myMobileNav" onClick={openNav}>
              <img
                src="https://cdn4.iconfinder.com/data/icons/wirecons-free-vector-icons/32/menu-alt-512.png"
                width="40px"
              />
            </span>

            <div
              id="mySidenav"
              ref={myMobileNavRef}
              class="sidenav"
              onMouseLeave={closeNav}
            >
              <div
                style={{
                  display: "flex",
                  width: "300px",
                  position: "relative",
                  paddingTop: "20px",
                }}
              >
                {/* <img src={newLOGO} alt="logo" width="230px" /> */}
                <a
                  href="javascript:void(0)"
                  class="closebtn"
                  onClick={closeNav}
                >
                  &times;
                </a>
              </div>
              <button
                className="drop-btn"
                onClick={() => handleSubLink("toggle")}
              >
                SERVICES
                <i className="fa fa-arrow-down"></i>
              </button>
              <div
                className="sub-links hide"
                ref={subMenuRef}
                onMouseLeave={() => handleSubLink("hide")}
              >
                <Link
                  onClick={closeNav}
                  to="/content/search-engine-optimization"
                  className="mobile-link"
                >
                  SEARCH ENGINE OPTIMIZATION
                </Link>
                <Link
                  onClick={closeNav}
                  to="/content/search-engine-optimization"
                  className="mobile-link"
                >
                  ONLINE REPUTATION MANAGEMENT
                </Link>
                <Link
                  onClick={closeNav}
                  to="/content/email-marketing"
                  className="mobile-link"
                >
                  EMAIL MARKETING
                </Link>
                <Link
                  onClick={closeNav}
                  to="/content/ASO"
                  className="mobile-link"
                >
                  ASO
                </Link>
                <Link
                  onClick={closeNav}
                  to="/content/content-writing"
                  className="mobile-link"
                >
                  CONTENT WRITING
                </Link>
                <Link
                  onClick={closeNav}
                  to="/content/content-marketing"
                  className="mobile-link"
                >
                  CONTENT MARKETING
                </Link>
                <Link
                  onClick={closeNav}
                  to="/content/pay-per-click"
                  className="mobile-link"
                >
                  PAY PER CLICK – PPC
                </Link>
                <Link
                  onClick={closeNav}
                  to="/content/social-media"
                  className="mobile-link"
                >
                  SOCIAL MEDIA MARKETING
                </Link>
              </div>
              <Link exact to="/" onClick={closeNav} className="mobile-link">
                Home
              </Link>
              <a href="#about" onClick={closeNav} className="mobile-link">
                About Us
              </a>
              <a href="#contact" onClick={closeNav} className="mobile-link">
                Contact Us
              </a>
            </div>

            <div className="nav-outer clearfix">
              {/*Mobile Navigation Toggler*/}
              <div className="mobile-nav-toggler">
                <span className="icon flaticon-menu" />
              </div>
              {/* Main Menu */}
              <nav className="main-menu navbar-expand-md">
                <div className="navbar-header">
                  {/* Toggle Button */}
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                  </button>
                </div>
                <div
                  className="navbar-collapse collapse clearfix"
                  id="navbarSupportedContent"
                >
                  <ul className="navigation clearfix">
                    {/* full logo start */}
                    <li style={{ margin: 0 }}>
                      <div>
                        <img
                          src={newLOGO}
                          onClick={() => history.push("/")}
                          style={{ width: "180px", cursor: "pointer" }}
                        />
                      </div>
                    </li>
                    {/* full logo end */}
                    {/* small logo start */}
                    {/* <li
                      style={{
                        minHeight: "73px",
                        display: "grid",
                        placeContent: "center",
                        margin: "0 0 0 10px",
                      }}
                    >
                      <div>
                        <img
                          src={smallLogo}
                          style={{
                            width: "90px",
                            margin: "0 0 0 10px",
                            paddingTop: "11px",
                          }}
                        />
                      </div>
                    </li> */}
                    {/* small logo end */}

                    <li
                      className="dropdown has-mega-menu"
                      onMouseOver={handleNavLinkOpen}
                      onMouseOut={handleNavLinkClick}
                    >
                      <a href="#features">
                        <span>
                          Services <i className="fa fa-arrow-down" />
                        </span>
                      </a>

                      <div
                        className="mega-menu"
                        id="mega-menu"
                        ref={megaMenuRef}
                      >
                        <div className="upper-box">
                          <div className="page-links-box">
                            <Link
                              to="/content/search-engine-optimization"
                              className="link"
                              style={styles.megaLink}
                              onClick={handleNavLinkClick}
                            >
                              <i class="fa fa-search" aria-hidden="true"></i>
                              SEARCH ENGINE OPTIMIZATION – SEO
                            </Link>
                            <Link
                              onClick={handleNavLinkClick}
                              to="/content/ASO"
                              className="link"
                              style={styles.megaLink}
                            >
                              <i
                                class="fa fa-map-marker"
                                aria-hidden="true"
                              ></i>
                              ASO
                            </Link>
                            <Link
                              onClick={handleNavLinkClick}
                              to="/content/social-media"
                              className="link"
                              style={styles.megaLink}
                            >
                              <i class="fa fa-hand-o-up" aria-hidden="true"></i>
                              SOCIAL MEDIA MARKETING
                            </Link>
                            <Link
                              onClick={handleNavLinkClick}
                              to="/content/pay-per-click"
                              className="link"
                              style={styles.megaLink}
                            >
                              <span className="icon flaticon-software" />
                              PAY PER CLICK – PPC
                            </Link>
                            <Link
                              onClick={handleNavLinkClick}
                              to="/content/email-marketing"
                              className="link"
                              style={styles.megaLink}
                            >
                              <i class="fa fa-envelope" aria-hidden="true"></i>
                              EMAIL MARKETING
                            </Link>
                            <Link
                              onClick={handleNavLinkClick}
                              to="/content/content-writing"
                              className="link"
                              style={styles.megaLink}
                            >
                              <i
                                class="fa fa-pencil-square-o"
                                aria-hidden="true"
                              ></i>
                              CONTENT WRITING
                            </Link>
                            <Link
                              onClick={handleNavLinkClick}
                              to="/content/content-marketing"
                              className="link"
                              style={styles.megaLink}
                            >
                              <span className="icon flaticon-team" />
                              CONTENT MARKETING
                            </Link>
                            <Link
                              onClick={handleNavLinkClick}
                              to="/content/reputation-management"
                              className="link"
                              style={styles.megaLink}
                            >
                              <span className="icon flaticon-language" />
                              ONLINE REPUTATION MANAGEMENT
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li className="current dropdown">
                      <a href="#home">{t("home")} </a>
                    </li>
                    <li className="dropdown">
                      {" "}
                      <a href="#about">{t("about_us")}</a>
                    </li>
                    {/* <li className="dropdown"><a href="#features" >{t('features')} </a>
                    <ul>
                      <li><a href="course.html">Courses</a></li>
                      <li><a href="course-2.html">Courses 02</a></li>
                      <li><a href="course-3.html">Courses 03</a></li>
                      <li><a href="course-4.html">Courses 04</a></li>
                      <li><a href="course-detail.html">Courses Detail</a></li>
                    </ul>
                  </li> */}
                    <li className="dropdown">
                      <a href="#contact">{t("contact_us")}</a>
                      {/* <ul>
                      <li><a href="blog.html">Our Blog</a></li>
                      <li><a href="blog-list.html">Blog List</a></li>
                      <li><a href="blog-detail.html">Blog Detail</a></li>
                      <li><a href="not-found.html">Not Found</a></li>
                    </ul> */}
                    </li>

                    <li style={{ marginTop: "15px" }} class="dropdown">
                      {/* <button style={{color:"rgb(67,185,126)"}}  class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  <GlobeIcon />
  </button> */}
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <span className="dropdown-item-text">
                            {t("language")}
                          </span>
                        </li>
                        {languages.map(({ code, name, country_code }) => (
                          <li key={country_code}>
                            <a
                              href="#"
                              className={classNames("dropdown-item", {
                                disabled: currentLanguageCode === code,
                              })}
                              onClick={() => {
                                i18next.changeLanguage(code);
                              }}
                            >
                              <span
                                className={`flag-icon flag-icon-${country_code} mx-2`}
                                style={{
                                  opacity:
                                    currentLanguageCode === code ? 0.5 : 1,
                                }}
                              ></span>
                              {name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li>
                  </ul>
                </div>
              </nav>
              {/* Main Menu End*/}
              <div className="outer-box clearfix">
                {/* Search */}
                {/* <div className="search-box">
                <form method="post" action="contact.html">
                  <div className="form-group">
                    <input type="search" name="search-field" defaultValue placeholder="What do we want learn" required />
                    <button type="submit"><span className="icon fa fa-search" /></button>
                  </div>
                </form>
              </div> */}
                {/* Social Box */}
                <ul className="social-box">
                  <li className="instagram">
                    <a
                      target="_blank"
                      href={SOCIAL_LINKS?.instagram || ""}
                      className="fa fa-instagram"
                    />
                  </li>
                  <li className="facebook">
                    <a
                      target="_blank"
                      href={SOCIAL_LINKS?.facebook || ""}
                      className="fa fa-facebook-f"
                    />
                  </li>
                  <li className="twitter">
                    <a
                      target="_blank"
                      href={SOCIAL_LINKS?.twitter || ""}
                      className="fa fa-twitter"
                    />
                  </li>
                  <li className="linkedIn">
                    <a
                      target="_blank"
                      href={SOCIAL_LINKS?.linkedin || ""}
                      className="fa fa-linkedin"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/*End Header Upper*/}
        {/* Sticky Header  */}
        <div className="sticky-header">
          <div className="auto-container clearfix">
            {/*Logo*/}
            <div className="logo pull-left">
              <a href="index.html" title>
                <img src="images/logo.png" alt title />
              </a>
            </div>
            {/*Right Col*/}
            <div className="pull-right">
              {/* Main Menu */}
              <nav className="main-menu">
                {/*Keep This Empty / Menu will come through Javascript*/}
              </nav>
              {/* Main Menu End*/}
              {/* Main Menu End*/}
              <div className="outer-box clearfix"></div>
            </div>
          </div>
        </div>
        {/* End Sticky Menu */}
        {/* Mobile Menu  */}
        <div className="mobile-menu">
          <div className="menu-backdrop" />
          <div className="close-btn">
            <span className="icon flaticon-multiply" />
          </div>
          <nav className="menu-box">
            <div className="nav-logo">
              <a href="index.html">
                <img src="images/logo.png" alt title />
              </a>
            </div>
            <div className="menu-outer">
              {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
            </div>
          </nav>
        </div>
        {/* End Mobile Menu */}
      </header>

      {/* <TrueHeader /> */}

      <div style={{ padding: "40px 0px" }} id="home">
        <Home />
      </div>

      <div style={{ padding: "40px 0px" }} id="features">
        <Features />
      </div>
      <div style={{ padding: "40px 0px" }} id="about">
        <About />
      </div>
      {/*   
    <div style={{padding:"40px 0px"}} id="demo">
      <Demo/>
    </div>
    <div style={{padding:"40px 0px"}} id="pricing">
      <Pricing/>
    </div> */}

      <div style={{ padding: "50px 0px" }} id="contact">
        <Contact />
      </div>

      <div style={{ padding: "50px 0px" }} id="faq">
        <Faq />
      </div>
    </>
  );
};

export default Header;

const styles = {
  megaLink: {
    padding: "5px",
    fontSize: "1rem",
  },
};
