import Routes from "./routes/Routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./css/animate.css";
import "./css/animation.css";
import "./css/custom-animate.css";
import "./css/flaticon.css";
import "./css/font-awesome.css";
import "./css/jquery.bootstrap-touchspin.css";
import "./css/jquery.fancybox.min.css";
import "./css/jquery.mCustomScrollbar.min.css";
import "./css/jquery-ui.css";
import "./css/owl.css";
import "./css/responsive.css";
import "./css/style.css";
import "./index.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NormalRoute from "./routes/NormalRoute";
function App() {
  return (
    <>
      <Router>
        <Switch>
          {/* <Route path="/">
            <Routes />
          </Route> */}
          <NormalRoute />
        </Switch>
      </Router>
    </>
  );
}
export default App;
