import "../content.css";
import "./content-writing.css";

import React, { useEffect, useState } from "react";
import pattern3 from "../../images/background/pattern-3.png";
import pattern4 from "../../images/background/pattern-4.png";
import pattern5 from "../../images/background/pattern-5.png";
import pattern6 from "../../images/background/pattern-6.png";
import benefit1 from "../../images/resource/benefit-1.jpg";
import benefit2 from "../../images/resource/benefit-2.png";
import benefit3 from "../../images/resource/benefit-3.jpg";
import cert from "../../images/resource/cert.png";
import cert1 from "../../images/resource/cert1.png";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Spinner from "../../components/spinner/Spinner";
const newLOGO = "new-logo-underline.png";

function ContentWriting() {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="main__content">
      {/* Benefit Section */}
      <section
        className="benefit-section"
        style={{
          padding: 0,
        }}
      >
        <div
          className="background-layer-one"
          style={{ backgroundImage: `url(${pattern5})` }}
        />
        <div
          className="background-layer-two"
          style={{ backgroundImage: `url(${pattern6})` }}
        />
        <div className="auto-container">
          <div className="row clearfix">
            {/* Images Column */}
            <div className="images-column col-lg-7 col-md-12 col-sm-12">
              <div
                style={{
                  // background: "red",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                }}
                className="inner-column"
              >
                <img
                  width="95%"
                  src={
                    "https://image.freepik.com/free-psd/laptop-iphone-digital-marketing-background_23-2148346689.jpg"
                  }
                />
                {/* <div
                  className="pattern-layer"
                  style={{ backgroundImage: `url(${pattern4})` }}
                />
                <div
                  className="pattern-layer-two"
                  style={{ backgroundImage: `url(${pattern3})` }}
                />
                <div className="color-layer" />
                <div className="image">
                  <img
                    src={
                      "https://image.freepik.com/free-psd/laptop-iphone-digital-marketing-background_23-2148346689.jpg"
                    }
                    alt
                  />
                </div>
                <div className="image-two">
                  <img
                    width="354px"
                    height="337px"
                    src={
                      "https://media-assets-02.thedrum.com/cache/images/thedrum-prod/s3-news-tmp-213062-hallam_digitalmarketing1--default--1280.jpg"
                    }
                    alt
                  />
                </div>
                <div className="image-three">
                  <img width="354px" height="337px" src={benefit2} alt />
                </div> */}
              </div>
            </div>
            {/* Content Column */}
            <div className="content-column col-lg-5 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="sec-title">
                  {/* <div className="title">Learn anything</div> */}
                  <h2 style={{ marginLeft: "10px" }}>Content Writing</h2>
                </div>
                <ul className="list-style-one">
                  {/* <li><span className="icon flaticon-double-check" /><h3> {t('about')}: </h3> <strong style={{color:"#9e7d18"}}> TM7 SERVICES INC</strong><p>{t('TM7_description')}</p></li>
            <div className="btn-box">
            <a href="https://www.tm7.tech/"
                        target="_blank" className="theme-btn btn-style-two"><span className="txt">{t('find_out_button')} TM7 SERVICES INC</span></a>
          </div> */}
                  <li
                    style={{
                      padding: "20px 60px 0px 10px",
                    }}
                  >
                    <strong style={{ color: "#9e7d18" }}>
                      Content Writing Services
                    </strong>
                    <p>
                      Content is the heart and soul of digital marketing. All of
                      your marketing efforts are useless without words. Because
                      words have the power to deliver your business message
                      successfully to the targeted audience. We don’t just focus
                      on improving your page ranking but also on creating the
                      buzz of your brand by delivering the right message to your
                      targeted audience. We offer all types of content writing
                      services whether it is an article, blogs, Guest posts,
                      Press Releases, Infographic content, website content and
                      so on. We cater to all your content requirements from
                      small to bulk orders. We provide a diverse range of
                      content writing solutions in India to meet all your
                      specific needs.
                    </p>
                  </li>
                  <div className="btn-box">
                    <a
                      href="#read"
                      // target="_blank"
                      className="theme-btn btn-style-two"
                    >
                      <span className="txt">Read More...</span>
                    </a>
                  </div>
                </ul>
              </div>
            </div>
          </div>

          <div className="section-1">
            <article className="item1">
              <section className="left">
                <h5>Use the Power of Words to Grow your Business</h5>
                Content is the heart and soul of digital marketing. All of your
                marketing efforts are useless without words. Because words have
                the power to deliver your business message successfully to the
                targeted audience. We don’t just focus on improving your page
                ranking but also on creating the buzz of your brand by
                delivering the right message to your targeted audience. We offer
                all types of content writing services whether it is an article,
                blogs, Guest posts, Press Releases, Infographic content, website
                content and so on. We cater to all your content requirements
                from small to bulk orders. We provide a diverse range of content
                writing solutions in India to meet all your specific needs.
              </section>
              <section className="right">
                <img src="https://webxeros.com/wp-content/uploads/2019/06/content-22.jpg" />
              </section>
            </article>
            {/* <article className="item2">
              <section className="left">
                Why You Need Content Writing for Your Business? The key to
                successful marketing is powerful content. Content writing helps
                to drive more traffic, improve SEO results and keep users
                engaged with the website. This is one of the best ways to
                communicate effectively with your potential audience. Frequently
                posting content on your website and other marketing channels
                lets people know what your business is about. Here are some
                benefits of content writing for your business. i) Strengthen
                your brand ii) Convey the right message to your targeted
                audience iii) Drive more organic search traffic iv) Engage the
                readers with website v) A good content can earn links from
                reputed websites vi) Great content is always shared vii) Helps
                to rank your website higher in all Search Engines, especially in
                Google
              </section>
              <section className="right">
                <img src="https://webxeros.com/wp-content/uploads/2019/06/content-22.jpg" />
              </section>
            </article> */}
            <article className="item3">
              <section className="left ">
                <h5>
                  Reasons for Choosing Our Content Writing Services in India?
                </h5>
                We are the top rated content writing company in India. We are
                into this field since 2012. We have vast experience and that’s
                why we are specialized in providing you with creative,
                informative and effective content writing for your website. Our
                aim is to satisfy our clients with each and every text. We have
                combined team of content curators, editors and quality analysts
                who works together to deliver high quality & rich content.
                Moreover, here are some things that make us a great choice for
                your business:
                <ul>
                  <li>8+ Years of Experience</li>
                  <li>Quality Assurance</li>
                  <li>Algorithm & Audience Based Content</li>
                  <li>Experienced & Skilled Staff</li>
                  <li>Satisfaction Guaranteed</li>
                  <li>Competitive Pricing</li>
                </ul>
              </section>
              <section className="right">
                <img src="https://webxeros.com/wp-content/uploads/2019/06/content-22.jpg" />
              </section>
            </article>
          </div>

          {/* <div className="lower-text imp-section-colored">
            <p style={{ fontSize: "2rem", marginBottom: "60px" }}>
              Our Comprehensive Content Writing Services includes:
            </p>
            <div>
              <ul className="imp-section__list">
                <li className="imp-section__list-item">
                  <h4>Website Content Writing</h4>
                  <div className="body">
                    Unique, compelling and engaging content writing services to
                    build your brand reputation, get more visitors, leads and
                    sales. We optimize your content from SEO prospective to
                    ensure you higher ranking on all Search engines. We write an
                    effective content that helps your website to interact and
                    communicate with audience consistently.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <h4>Blog & Guest Post Writing</h4>
                  <div className="body">
                    We create high-quality SEO optimized blogs and guest posts
                    to maximize your SEO. We write blogs and guest posts on
                    those topics which have enough search volumes and trendy in
                    the market. We use dynamic content writing approach to
                    provide you in-demand content. We write the content as per
                    given instructions.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <h4>PR Writing</h4>
                  <div className="body">
                    We write a concise, readable and well-optimized press
                    release that increase your brand awareness and drive more
                    traffic to your website. Cost effective press release
                    writing and distribution services. We publish PR content on
                    high authority and trustworthy websites that have DA of 30+.
                    That makes a big difference in your website ranking.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <h4>Article Writing</h4>
                  <div className="body">
                    We write well-researched and highly informative articles to
                    attract the visitors, get their attention and drive more
                    traffic to the website. Additionally, we publish your
                    article on the popular article websites that are
                    trustworthy, have great users database and high authority.
                    We build up your brand reputation online.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <h4>SEO Copywriting</h4>
                  <div className="body">
                    We write relevant, original and well-optimized SEO content
                    writing services in India to maximize your SEO efforts. We
                    help to increase your SEO ranking, conversion rate and get
                    more organic traffic. Our team of digital marketing experts
                    create keywords rich content and distribute it on marketing
                    platforms to get more exposures.
                  </div>
                </li>
                <li className="imp-section__list-item">
                  <h4>Social Media Writing</h4>
                  <div className="body">
                    We develop social media content from per day posting to paid
                    ads campaigns. Brand specific content that attract the
                    attentions of users worldwide. We write content on relevant
                    topics and share it on the targeted groups to increase your
                    reach. Effective social media content writing services to
                    promote your products or services.
                  </div>
                </li>
              </ul>
            </div>
          </div> */}

          {/* ''''''''''''''''''''''''''''''''''' */}
          <div
            id="read"
            className="blocks-column col-lg-12 col-md-12 col-sm-12"
          >
            <div className="title">
              <h3
                style={{
                  color: "#06092d",
                  fonSize: "2.2rem",
                  fontWeight: 800,
                  textAlign: "center",
                  padding: "0",
                  marginTop: "40px",
                  marginBottom: "30px",
                }}
              >
                Our Comprehensive Content Writing Services includes:
              </h3>
              {/* <p className="primary_text" style={{ margin: "40px 10px" }}></p> */}
            </div>
            <div className="inner-column">
              <div className="row clearfix">
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/social-media"
                      >
                        Website Content Writing
                      </a>
                    </h4>
                    <div className="text">
                      Unique, compelling and engaging content writing services
                      to build your brand reputation, get more visitors, leads
                      and sales. We optimize your content from SEO prospective
                      to ensure you higher ranking on all Search engines. We
                      write an effective content that helps your website to
                      interact and communicate with audience consistently.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/pay-per-click"
                      >
                        Blog & Guest Post Writing
                      </a>
                    </h4>
                    <div className="text">
                      We create high-quality SEO optimized blogs and guest posts
                      to maximize your SEO. We write blogs and guest posts on
                      those topics which have enough search volumes and trendy
                      in the market. We use dynamic content writing approach to
                      provide you in-demand content. We write the content as per
                      given instructions.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="#/content/email-marketing"
                      >
                        PR Writing
                      </a>
                    </h4>
                    <div className="text">
                      We write a concise, readable and well-optimized press
                      release that increase your brand awareness and drive more
                      traffic to your website. Cost effective press release
                      writing and distribution services. We publish PR content
                      on high authority and trustworthy websites that have DA of
                      30+. That makes a big difference in your website ranking.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/content-writing"
                      >
                        Article Writing
                      </a>
                    </h4>
                    <div className="text">
                      We write well-researched and highly informative articles
                      to attract the visitors, get their attention and drive
                      more traffic to the website. Additionally, we publish your
                      article on the popular article websites that are
                      trustworthy, have great users database and high authority.
                      We build up your brand reputation online.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/reputation-management"
                      >
                        SEO Copywriting
                      </a>
                    </h4>
                    <div className="text">
                      We write relevant, original and well-optimized SEO content
                      writing services in India to maximize your SEO efforts. We
                      help to increase your SEO ranking, conversion rate and get
                      more organic traffic. Our team of digital marketing
                      experts create keywords rich content and distribute it on
                      marketing platforms to get more exposures.
                    </div>
                  </div>
                </div>

                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/conversion-optimization"
                      >
                        Social Media Writing
                      </a>
                    </h4>
                    <div className="text">
                      We develop social media content from per day posting to
                      paid ads campaigns. Brand specific content that attract
                      the attentions of users worldwide. We write content on
                      relevant topics and share it on the targeted groups to
                      increase your reach. Effective social media content
                      writing services to promote your products or services.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ''''''''''''''''''''''''''''''''''' */}
        </div>
      </section>
      {/* End Benefit Section */}
    </div>
  );
}

export default ContentWriting;
