import "../content.css";

import React, { useEffect, useState } from "react";
import pattern3 from "../../images/background/pattern-3.png";
import pattern4 from "../../images/background/pattern-4.png";
import pattern5 from "../../images/background/pattern-5.png";
import pattern6 from "../../images/background/pattern-6.png";
import benefit1 from "../../images/resource/benefit-1.jpg";
import benefit2 from "../../images/resource/benefit-2.png";
import benefit3 from "../../images/resource/benefit-3.jpg";
import cert from "../../images/resource/cert.png";
import cert1 from "../../images/resource/cert1.png";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Spinner from "../../components/spinner/Spinner";
const newLOGO = "new-logo-underline.png";

function ASO() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="main__content">
      {/* Benefit Section */}
      <section
        className="benefit-section"
        style={{
          padding: 0,
        }}
      >
        <div
          className="background-layer-one"
          style={{ backgroundImage: `url(${pattern5})` }}
        />
        <div
          className="background-layer-two"
          style={{ backgroundImage: `url(${pattern6})` }}
        />
        <div className="auto-container">
          <div className="row clearfix">
            {/* Images Column */}
            <div className="images-column col-lg-7 col-md-12 col-sm-12">
              <div
                style={{
                  // background: "red",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                }}
                className="inner-column"
              >
                <img
                  width="95%"
                  src={
                    "https://image.freepik.com/free-psd/laptop-iphone-digital-marketing-background_23-2148346689.jpg"
                  }
                />
                {/* <div
                  className="pattern-layer"
                  style={{ backgroundImage: `url(${pattern4})` }}
                />
                <div
                  className="pattern-layer-two"
                  style={{ backgroundImage: `url(${pattern3})` }}
                />
                <div className="color-layer" />
                <div className="image">
                    alt
                  />
                </div>
                <div className="image-two">
                  <img
                    width="354px"
                    height="337px"
                    src={
                      "https://media-assets-02.thedrum.com/cache/images/thedrum-prod/s3-news-tmp-213062-hallam_digitalmarketing1--default--1280.jpg"
                    }
                    alt
                  />
                </div>
                <div className="image-three">
                  <img width="354px" height="337px" src={benefit2} alt />
                </div> */}
              </div>
            </div>
            {/* Content Column */}
            <div className="content-column col-lg-5 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="sec-title">
                  {/* <div className="title">Learn anything</div> */}
                  <h2 style={{ marginLeft: "10px" }}>ASO:</h2>
                </div>
                <ul className="list-style-one">
                  {/* <li><span className="icon flaticon-double-check" /><h3> {t('about')}: </h3> <strong style={{color:"#9e7d18"}}> TM7 SERVICES INC</strong><p>{t('TM7_description')}</p></li>
            <div className="btn-box">
            <a href="https://www.tm7.tech/"
                        target="_blank" className="theme-btn btn-style-two"><span className="txt">{t('find_out_button')} TM7 SERVICES INC</span></a>
          </div> */}
                  <li
                    style={{
                      padding: "20px 60px 0px 10px",
                    }}
                  >
                    <strong style={{ color: "#9e7d18" }}>
                      App store optimization services
                    </strong>
                    <p>
                      The approach of boosting an app's organic visibility and
                      downloads in app stores is referred to as App Store
                      Optimization (ASO). It's critical to understand the
                      different facets of each app store's search algorithm to
                      develop a successful ASO strategy.
                      <br />
                      At Draw Topic Solution, We provide the best mobile app
                      marketing packages that are the most effective to promote
                      your app and get it noticed, resulting in increased
                      visibility, engagement, and downloads. We provide unique
                      packages and customization for app store optimization that
                      will suit your companies' services. As a result of this,
                      Customers can more precisely address their company
                      demands.
                    </p>
                  </li>
                  <div className="btn-box">
                    <a href="#READMORE" className="theme-btn btn-style-two">
                      <span className="txt">Read More...</span>
                    </a>
                  </div>
                </ul>
              </div>
            </div>
          </div>
          {/* UPDATED CONTENT START */}
          <div id="READMORE">
            {/* ASO:*/}
            <section style={{ margin: "0 5%" }}>
              <h3 style={styles.title}> App store optimization service:</h3>
              <div>
                <p style={styles.paraghrapText}>
                  The approach of boosting an app's organic visibility and
                  downloads in app stores is referred to as App Store
                  Optimization (ASO). It's critical to understand the different
                  facets of each app store's search algorithm to develop a
                  successful ASO strategy.
                </p>
                <p style={styles.paraghrapText}>
                  At Draw Topic Solution, We provide the best mobile app
                  marketing packages that are the most effective to promote your
                  app and get it noticed, resulting in increased visibility,
                  engagement, and downloads. We provide unique packages and
                  customization for app store optimization that will suit your
                  companies' services. As a result of this, Customers can more
                  precisely address their company demands.
                </p>
                <p style={styles.paraghrapText}>
                  We live in a world where every street is participating in
                  marketing competitions and to win with the greatest result
                  expertise is required. App Store Optimization is a part of the
                  services that we deliver at Draw Topic Solution. We offer all
                  the resources you need to succeed in the app stores, from
                  analytics to keywords to screenshots and sample videos.
                  <h6 style={{ fontWeight: "bold" }}>
                    Get in touch with us to Enjoy our classic SEO service.{" "}
                  </h6>
                </p>
              </div>
            </section>
            {/* Need Of Email Marketing:: */}
            <section style={{ margin: "0 5%" }}>
              <h3 style={styles.title}>Need Of ASO:</h3>
              <div>
                <p style={styles.paraghrapText}>
                  One of the most important aspects of the mobile marketing
                  funnel is ASO. Organically attracting users to your app might
                  be the most cost-effective strategy to accomplish app growth.
                  Furthermore, the app store has nearly five million apps. In
                  such a competitive market, you must maximize every single
                  component to stand out. ASO is what helps you get a high
                  position in the app stores, improving your visibility and
                  making your app more likely to be downloaded than a rival.
                </p>
                <p style={styles.paraghrapText}>
                  You'll surely slip behind your competition if you don't work
                  on your app's ASO. Every app store is expanding at a rapid
                  speed, and they're all trying to enhance their search engines.
                  Taking advantage of ASO will help your app develop and gain
                  more downloads.
                </p>
                <p style={styles.paraghrapText}>
                  Every day, we work with a high level of expertise and
                  enthusiasm. Draw Topic Solutions is a full-service app store
                  optimization firm. To provide a complete customization
                  approach, we conduct research and analysis using our
                  technologies and qualified experts. Our efforts have resulted
                  in increased search visibility and conversions, allowing us to
                  optimize organic visitor growth.
                </p>
              </div>
            </section>
            {/* WHY CHOOSE DRAW TOPIC: */}
            <section style={{ margin: "0 5%" }}>
              <h3 style={styles.title}>WHY CHOOSE DRAW TOPIC:</h3>
              <div>
                <p style={styles.paraghrapText}>
                  Draw Topic Solution takes complete responsibility for your
                  app’s growth in our hands. We know exactly which features need
                  to be optimized for maximum success, with a good understanding
                  of how each app algorithm works. Among other things, we
                  undertake keyword research to improve app names, subtitles,
                  and descriptions, A/B test creatives, and conduct competition
                  and product analysis. All of this is done to improve your
                  app's conversions. Choosing the best ASO agency benefits in
                  the same way as choosing the best doctor when you are sick.
                </p>
                <p style={styles.paraghrapText}>
                  Only top-tier firms, like Draw Topic Solutions, can give the
                  top edge results that you deserve. To take this advantage from
                  your apps there's no better time than now to start. We
                  completely focus on your apps, optimizing all of their aspects
                  and managing their ranking. The tasks included, but are not
                  restricted to:
                </p>

                <ul className="importance_ul">
                  <li>The app becomes pretty easy to find.</li>
                  <li>Increases traffic</li>
                  <li>The number of users continues to rise.</li>
                  <li>Increases the number of organic downloads</li>
                  <li>Increases the number of users</li>
                  <li>As the CTR rises, so does the income.</li>
                  <li>Aids in the expansion of your mobile business</li>
                </ul>
              </div>
            </section>
          </div>
          {/* Know Our ASO STRATEGY and Why It is the Best!
           */}
          <section style={{ margin: "0 5%" }}>
            <h3 style={styles.title}>
              Know Our ASO STRATEGY and Why It is the Best!
            </h3>
            <div>
              <p style={styles.paraghrapText}>
                App store optimization services are comparable to search engine
                optimization (SEO). It improves mobile app distribution by
                increasing your app's search ranking in the app store.
              </p>
              <p style={styles.paraghrapText}>
                Strategies that are used for doing ASO are well-guarded secrets.
                Neither Google nor Apple reveals their actual app ranking
                process. Superficially We can speak, Like SEO, algorithms are
                used, We do pay attention to all the following terms like
                Keyword analysis, Optimising metadata, Using the wise
                descriptive title and description, Placing your app in the
                proper category, Visible Content Redesign, Providing positive
                feedback, Improving app listings more localized, Keeping your
                app's quality high, Taking use of app store analytics, Conduct
                Research and Testing of your app and more that may require.
              </p>
              <p style={styles.paraghrapText}>
                We believe in developing a robust, long-term approach that
                allows for continuous enhancement. Choose Draw Topic Solution
                for making your apps ASO successful in the most affordable way.
              </p>
            </div>
          </section>
          {/* UPDATED CONTENT END */}
        </div>
      </section>
      {/* End Benefit Section */}
    </div>
  );
}
export default ASO;
const styles = {
  title: {
    color: "#06092d",
    fonSize: "2.1rem",
    fontWeight: 800,
    textAlign: "center",
    padding: "0",
    marginTop: "40px",
    marginBottom: "30px",
  },
  paraghrapText: {
    color: "#222",
    fontSize: "1.2rem",
  },
};
