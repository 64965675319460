import React, { useState, useEffect } from "react";
import "./demo.css";
import { useStyles } from "./styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { blue } from "@material-ui/core/colors";
import CssBaseline from "@material-ui/core/CssBaseline";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import { Typography, Container, CardMedia } from "@material-ui/core";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Fab from "@material-ui/core/Fab";
import CheckIcon from "@material-ui/icons/Check";
import SaveIcon from "@material-ui/icons/Save";
import { useHistory } from "react-router-dom";
import Popup from "../../components/Popup";
import Controls from "../../components/controls/Controls";
import Axios from "axios";
import LazyLoad from "react-lazyload";
import pattern3 from '../../images/background/pattern-3.png'
import pattern4 from '../../images/background/pattern-4.png'
import pattern5 from '../../images/background/pattern-5.png'
import pattern6 from '../../images/background/pattern-6.png'
import benefit1 from '../../images/resource/benefit-1.jpg'
// import benefit2 from '../../images/resource/benefit-2.jpg'
import benefit3 from '../../images/resource/benefit-3.jpg' 
import { useTranslation } from 'react-i18next'

const theme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: blue[600],
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#11cb5f",
    },
  },
});

const cards1 = [
  { title: "video1 title", video: "https://www.youtube.com/embed/iMP3Zn2X9sg" },
  { title: "video1 title", video: "https://www.youtube.com/embed/iMP3Zn2X9sg" },
  { title: "video1 title", video: "https://www.youtube.com/embed/iMP3Zn2X9sg" },
  { title: "video1 title", video: "https://www.youtube.com/embed/iMP3Zn2X9sg" },
  { title: "video1 title", video: "https://www.youtube.com/embed/iMP3Zn2X9sg" },
  { title: "video1 title", video: "https://www.youtube.com/embed/iMP3Zn2X9sg" },
];

const Gallary = () => {
  const { t } = useTranslation()
  const classes = useStyles();
  const [toggle, settoggle] = useState(false);
  const [cards, setCard] = useState(cards1);
  const [openPopup, setOpenPopup] = useState(false);
  const setGallery = () => {
    const id = localStorage.getItem("id");
    const token = localStorage.getItem("token");
  };
  useEffect(() => {
    setGallery();
  }, [toggle]);

  const [success, setSuccess] = React.useState(false);

  const [imageLink, setImageLink] = useState();

  const timer = React.useRef();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });




  return (
    <ThemeProvider theme={theme}>
      <section className="benefit-section1">
  <div className="background-layer-one" style={{backgroundImage: `url(${pattern5})`}} />
  <div className="background-layer-two" style={{backgroundImage: `url(${pattern6})`}} />
  <div className="auto-container">
    <div className="row clearfix">
      {/* Images Column */}
      
      <React.Fragment>
       
        <div style={{display:"grid", placeItems:"center",width:"100vw"}}>
        <main >
          {/* Hero unit */}
        
            <div style={{ marginTop: "20vh", marginLeft: "5vw" }}>
              <Grid item xs={12} sm={12} lg={12}>
                <Typography
                  component="h1"
                  variant="h3"
                  align="center"
                  style={{color:'rgb(67,185,126)'}}
                  gutterBottom
                >
                  {t('see_our')}
                </Typography>
              </Grid>
            </div>
         

          <Container className={classes.cardGrid} maxWidth="md">
            {/* End hero unit */}

            <Grid container spacing={4}>
              {cards &&
                cards.map((card, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4}>
                    <Card className={classes.card}>
                      <LazyLoad
                        height={200}
                        placeholder={
                          <h1 style={{ marginTop: "30vh" }}>Loading...</h1>
                        }
                      >
                        <div className="row">
                          <div className="col-md-4">
                            <div className="video_box small_video">
                              <iframe
                                src={card.video}
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                              ></iframe>
                            </div>
                          </div>
                        </div>
                      </LazyLoad>

                      <CardActions>
                        <Button
                          size="small"
                          onClick={() => {
                            setImageLink(card.video);
                            setOpenPopup(!openPopup);
                          }}
                          color="primary"
                        >
                          {t('large_view')}
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </Container>
          <Popup
            title="Video"
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
          >
            <div style={{ minWidth: "80vw" }}>
              <div className="video_outer">
                <div className="row">
                  <div className="col-10">
                    <div className="video_box first_video">
                      <iframe
                        src={imageLink}
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Popup>
        </main>
        </div>
      </React.Fragment>
    
      </div>
    {/* Lower Text */}
 
  </div>
</section>
      
    </ThemeProvider>
  );
};
export default Gallary;
