import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as validator from "../../utilities/validator";
import { SOCIAL_LINKS } from "../../constants/socialLinks";

const Contact = () => {
  const { t } = useTranslation();

  const initialMsgData = {
    name: "",
    email: "",
    message: "",
  };

  const [finalValidate, setfinalValidate] = useState(false);

  const [loading, setLoading] = useState(false);

  const [msgData, setMsgData] = useState(initialMsgData);

  const [errdata, setErrdata] = useState(initialMsgData);

  const [responseMsg, setResponseMsg] = useState({ color: "", msg: "" });

  const setValues = (e) => {
    let errObj = {};
    const { name, value } = e.target;

    setMsgData({ ...msgData, [name]: value });

    setResponseMsg({ ...responseMsg, msg: "" });

    switch (name) {
      case "email": {
        if (validator.isValidEmail(value)) {
          errObj = { ...errdata, [name]: false };

          setErrdata(errObj);
        } else {
          errObj = { ...errdata, [name]: true };
          setErrdata(errObj);
        }
        break;
      }
      case "name": {
        if (validator.isFieldEmpty(value)) {
          errObj = { ...errdata, [name]: false };
          setErrdata(errObj);
        } else {
          if (value.length) {
            errObj = { ...errdata, [name]: true };
            setErrdata(errObj);
          }
        }
        break;
      }
      case "message": {
        if (validator.isFieldEmpty(value)) {
          errObj = { ...errdata, [name]: false };

          setErrdata(errObj);
        } else {
          errObj = { ...errdata, [name]: true };
          setErrdata(errObj);
        }
        break;
      }
      default: {
      }
    }
  };
  const sendDetails = async () => {
    setLoading(true);
    console.log(msgData);
    const query = `https://drawtopic.digital:4603/api/users/sendMail`;
    console.log(JSON.stringify(msgData));
    try {
      const response = await fetch(query, {
        method: "POST",
        body: JSON.stringify(msgData),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      setLoading(false);

      const responseObj = await response.json();

      console.log(responseObj);
      if (responseObj.isSuccess == true) {
        setMsgData(initialMsgData);
        setErrdata(initialMsgData);
        setResponseMsg({ color: "green", msg: "Details sent Successfully" });
        setfinalValidate(true);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setResponseMsg({ color: "red", msg: "Something went wrong" });
    }
  };

  const submit = (e) => {
    e.preventDefault();
    const finalValid = Object.values(errdata).every(Boolean);
    console.log(Object.values(errdata));

    if (finalValid) {
      sendDetails();
    } else {
      setResponseMsg({
        color: "red",
        msg: "Please fill all feilds with valid values",
      });
    }
  };

  return (
    <>
      {/* Contact Page Section */}
      <section className="contact-page-section">
        <div
          className="pattern-layer-three"
          style={{ backgroundImage: "url(images/icons/icon-8.png)" }}
        />
        <div className="auto-container">
          <div className="row clearfix">
            {/* Info Column */}
            <div className="info-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="title">Get In Touch</div>
                <h2>{t("feel_free")}</h2>
                <div className="text">
                  <h5>{t("we_are_open")}</h5>{" "}
                </div>
                <ul>
                  <li>
                    <span>{t("address")}</span>
                    {SOCIAL_LINKS.address}
                  </li>
                  <li>
                    <span>{t("our_phone")}</span>
                    {t("telephone")} :{`(+91)-${SOCIAL_LINKS.phone_no}`}
                  </li>
                  <li>
                    <span>{t("opening_hours")}</span>
                    {t("monday_to_saturday")}: 9AM to 7PM
                  </li>
                </ul>
              </div>
            </div>
            {/* Form Column */}
            <div className="form-column col-lg-6 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="circle-layer" />
                <div
                  className="pattern-layer-one"
                  style={{ backgroundImage: "url(images/icons/icon-7.png)" }}
                />
                <div
                  className="pattern-layer-two"
                  style={{ backgroundImage: "url(images/icons/icon-9.png)" }}
                />
                <h2>{t("leave_a_message")}</h2>
                <div className="text">
                  We are open for any business opportunities as a software
                  company. You can contact us at hi@drawtopic.digital or use the
                  form on the right to reach us.
                </div>
                {/* Contact Form */}
                <div className="contact-form">
                  <form>
                    <div className="form-group">
                      <input
                        type="text"
                        name="name"
                        onChange={(e) => setValues(e)}
                        placeholder={t("name")}
                        required
                      />
                      <p style={{ color: "red", fontSize: "14px" }}>
                        {errdata.name === false && "This feild can't be empty"}
                      </p>
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        value={msgData.email || ""}
                        onChange={(e) => setValues(e)}
                        placeholder={t("email")}
                        required
                      />
                      <p style={{ color: "red", fontSize: "14px" }}>
                        {errdata.email === false && "Enter a valid email"}
                      </p>
                    </div>

                    <div className="form-group">
                      <textarea
                        className
                        name="message"
                        value={msgData.message || ""}
                        onChange={(e) => setValues(e)}
                        placeholder={t("message")}
                      />
                      <p style={{ color: "red", fontSize: "14px" }}>
                        {errdata.message === false &&
                          "This feild can't be empty"}
                      </p>
                    </div>
                    <div className="form-group">
                      <button
                        className="theme-btn btn-style-five"
                        type="button"
                        onClick={(e) => submit(e)}
                        name="submit-form"
                      >
                        {loading ? (
                          <div
                            style={{ fontSize: "10px" }}
                            className="spinner-border"
                            role="status"
                          >
                            <span className="visually-hidden"></span>
                          </div>
                        ) : (
                          "Send Message"
                        )}
                      </button>
                      <small
                        style={{
                          fontSize: "14px",
                          paddingTop: "5px",
                          color: responseMsg.color,
                          display: "block",
                        }}
                      >
                        {responseMsg.msg}
                      </small>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Contact Page Section */}
    </>
  );
};

export default Contact;
