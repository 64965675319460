import "../content.css";

import React, { useEffect, useState } from "react";
import pattern3 from "../../images/background/pattern-3.png";
import pattern4 from "../../images/background/pattern-4.png";
import pattern5 from "../../images/background/pattern-5.png";
import pattern6 from "../../images/background/pattern-6.png";
import benefit1 from "../../images/resource/benefit-1.jpg";
import benefit2 from "../../images/resource/benefit-2.png";
import benefit3 from "../../images/resource/benefit-3.jpg";
import cert from "../../images/resource/cert.png";
import cert1 from "../../images/resource/cert1.png";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Spinner from "../../components/spinner/Spinner";
const newLOGO = "new-logo-underline.png";

function EmailMarketing() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="main__content">
      {/* Benefit Section */}
      <section
        className="benefit-section"
        style={{
          padding: 0,
        }}
      >
        <div
          className="background-layer-one"
          style={{ backgroundImage: `url(${pattern5})` }}
        />
        <div
          className="background-layer-two"
          style={{ backgroundImage: `url(${pattern6})` }}
        />
        <div className="auto-container">
          <div className="row clearfix">
            {/* Images Column */}
            <div className="images-column col-lg-7 col-md-12 col-sm-12">
              <div
                style={{
                  // background: "red",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: 0,
                }}
                className="inner-column"
              >
                <img
                  width="95%"
                  src={
                    "https://image.freepik.com/free-psd/laptop-iphone-digital-marketing-background_23-2148346689.jpg"
                  }
                />
                {/* <div
                  className="pattern-layer"
                  style={{ backgroundImage: `url(${pattern4})` }}
                />
                <div
                  className="pattern-layer-two"
                  style={{ backgroundImage: `url(${pattern3})` }}
                />
                <div className="color-layer" />
                <div className="image">
                  <img
                    src={
                      "https://image.freepik.com/free-psd/laptop-iphone-digital-marketing-background_23-2148346689.jpg"
                    }
                    alt
                  />
                </div>
                <div className="image-two">
                  <img
                    width="354px"
                    height="337px"
                    src={
                      "https://media-assets-02.thedrum.com/cache/images/thedrum-prod/s3-news-tmp-213062-hallam_digitalmarketing1--default--1280.jpg"
                    }
                    alt
                  />
                </div>
                <div className="image-three">
                  <img width="354px" height="337px" src={benefit2} alt />
                </div> */}
              </div>
            </div>
            {/* Content Column */}
            <div className="content-column col-lg-5 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="sec-title">
                  {/* <div className="title">Learn anything</div> */}
                  <h2 style={{ marginLeft: "10px" }}>Email Marketing:</h2>
                </div>
                <ul className="list-style-one">
                  {/* <li><span className="icon flaticon-double-check" /><h3> {t('about')}: </h3> <strong style={{color:"#9e7d18"}}> TM7 SERVICES INC</strong><p>{t('TM7_description')}</p></li>
            <div className="btn-box">
            <a href="https://www.tm7.tech/"
                        target="_blank" className="theme-btn btn-style-two"><span className="txt">{t('find_out_button')} TM7 SERVICES INC</span></a>
          </div> */}
                  <li
                    style={{
                      padding: "20px 60px 0px 10px",
                    }}
                  >
                    <strong style={{ color: "#9e7d18" }}>
                      Email Marketing:
                    </strong>
                    <p>
                      Email advertising is a major marketing approach used by
                      numerous organizations across the world to develop brand
                      awareness, revenues, and client connections. Different
                      email service providers are used by email marketing
                      organizations to send out automated, tailored marketing
                      emails with brand promotions and announcements. Email
                      marketing includes anything from weekly email newsletters
                      and discount alerts to customer survey forms and event
                      invites.
                    </p>
                  </li>
                  <div className="btn-box">
                    <a
                      href="#READMORE"
                      // target="_blank"
                      className="theme-btn btn-style-two"
                    >
                      <span className="txt">Read More...</span>
                    </a>
                  </div>
                </ul>
              </div>
            </div>
          </div>
          {/* UPDATED CONTENT START */}
          <div id="READMORE">
            {/* Email Marketing: */}
            <section style={{ margin: "0 5%" }}>
              <h3 style={styles.title}>Email Marketing:</h3>
              <div>
                <p style={styles.paraghrapText}>
                  Email advertising is a major marketing approach used by
                  numerous organizations across the world to develop brand
                  awareness, revenues, and client connections. Different email
                  service providers are used by email marketing organizations to
                  send out automated, tailored marketing emails with brand
                  promotions and announcements. Email marketing includes
                  anything from weekly email newsletters and discount alerts to
                  customer survey forms and event invites.
                </p>
                <p style={styles.paraghrapText}>
                  Businesses are expanding globally facilitated by social media
                  platforms. You may simply interact with your clients and grow
                  your business with the aid of social media marketing. Our
                  services will assist you with all areas of your SMO plan,
                  including social media content creation, site management,
                  development, and social media marketing approach.
                </p>
                <p style={styles.paraghrapText}>
                  There is no more strong digital marketing approach than email
                  marketing when it comes to creating and retaining leads.
                  Despite the rise in popularity of mobile messaging and chat
                  apps, studies reveal that approximately every customer prefers
                  facilitated business contacts via email. Email marketing is
                  effective than ever before, because of its broad use and
                  growing global relevance.
                </p>
                <p style={styles.paraghrapText}>
                  At Draw Topic Solution, we offer email marketing services to
                  assist you to interact with your prospective customers
                  directly. We provide cost-effective marketing solutions to
                  provide your company with all marketing benefits by increasing
                  its internet presence. The purpose of email marketing isn't
                  only to send emails to a specified number of people. It's all
                  about making a connection with potential consumers or clients
                  and building a trusting relationship with them.
                  <br />
                  Start developing your email marketing strategy now to select
                  the most effective way to drive your mail account down your
                  marketing cycle. We offer a wide range of email marketing
                  services suited to your brand's goals, budget, and target
                  market segments. Get in touch with us to Enjoy our classic
                  Email Marketing service.
                </p>
              </div>
            </section>
            {/* Need Of Email Marketing:: */}
            <section style={{ margin: "0 5%" }}>
              <h3 style={styles.title}>Need Of Email Marketing:</h3>
              <div>
                <p style={styles.paraghrapText}>
                  Many entrepreneurs raise the question that if social media
                  platforms are so extensively available and traffic has risen
                  rapidly over time through this, then why email marketing is so
                  essential. But here's the point: people have stronger faith in
                  emails than social media, they still check their inboxes for
                  anything that relates to the business or they care about. They
                  still choose emails for business promotions and see social
                  media sites as an additional side-by-side methodology for
                  marketing. With the abundance of opportunities that online
                  email marketing provides, you're one step closer to increasing
                  conversions for your company.
                </p>
                <p style={styles.paraghrapText}>
                  The role of email marketing in the success of a digital
                  company cannot be overstated. One of the most effective
                  marketing tactics for generating company leads and a high
                  profit is online email marketing (ROI). Email marketing offers
                  an excellent opportunity to meet the target audience and
                  enhance revenue. If you haven't already begun preparing your
                  email marketing strategy, now is the time to meet with email
                  marketing experts like us and map out a plan for moving your
                  company ahead.
                </p>

                <h6 style={{ ...styles.title, textAlign: "left" }}>
                  Some more convincing reasons to choose an email marketing
                  provider are as follows:
                </h6>
                <ul className="importance_ul">
                  <li>Build Loyalty.</li>
                  <li>Audience Engagement.</li>
                  <li>Expand Global Reach.</li>
                  <li>{"Save Effort & Time."}</li>
                  <li>Gives Instant and Strong Results.</li>
                  <li>Track Analytics.</li>
                  <li>Cost-Effective.</li>
                  <li>Manage Customer Relationship.</li>
                </ul>
                <p style={styles.paraghrapText}>
                  Schedule a meeting with our company to explore the benefits of
                  email marketing in attaining your targets.
                </p>
              </div>
            </section>
            {/* WHY CHOOSE DRAW TOPIC: */}
            <section style={{ margin: "0 5%" }}>
              <h3 style={styles.title}>WHY CHOOSE DRAW TOPIC:</h3>
              <div>
                <p style={styles.paraghrapText}>
                  Our email marketing strategies maintain the highest level of
                  internet marketing standards. We keep a close eye on the
                  patterns that become popular at a specific moment and then
                  switch. The ever-changing markets demand regular changes in
                  email marketing techniques as well. We maintain watch among
                  these markets and, using our experience and knowledge, develop
                  plans that help you succeed. Our plans promise you to provide
                  all that is possible out of them. Every effort we make during
                  the campaign's execution is aimed at exceeding, not just
                  meeting, your expectations.
                </p>
                <p style={styles.paraghrapText}>
                  So it can be noted that email marketing has been the most
                  prominent type of direct marketing, and it plays a significant
                  part in raising brand recognition.
                  <br />
                  Draw Topic Solution’s email marketing services assist you in
                  building a large consumer base most productively! And for
                  providing you with all of its benefits we use a systematic
                  approach of email marketing services, which raise the value of
                  your business digitally.
                </p>
              </div>
            </section>
          </div>
          {/* UPDATED CONTENT END */}

          <div className="blocks-column col-lg-12 col-md-12 col-sm-12">
            <div className="title">
              <h3
                style={{
                  color: "#06092d",
                  fonSize: "2.2rem",
                  fontWeight: 800,
                  textAlign: "center",
                  padding: "0",
                  marginTop: "40px",
                  marginBottom: "30px",
                }}
              >
                Know Our SEO STRATEGY and Why It is the Best!
              </h3>
              <p className="primary_text" style={{ margin: "20px 10px" }}>
                An email has been the most cost-effective method of promoting
                your products, communicating with clients, and achieving your
                company objectives. This strategy provides businesses with a
                direct line of communication to promote brands.
              </p>

              <h6 style={{ ...styles.title, textAlign: "left" }}>
                These are the following strategies we provide more:
              </h6>
            </div>
            <div className="inner-column">
              <div className="row clearfix">
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/social-media"
                      >
                        Personalize your messages:
                      </a>
                    </h4>
                    <div className="text">
                      Personalization refers to the use of consumer information
                      to generate a customized message. Always look over the
                      unique approaches to communicate with your customer. With
                      a clever personalized message, show your clients that you
                      know who they are.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/pay-per-click"
                      >
                        Segment your email list:
                      </a>
                    </h4>
                    <div className="text">
                      Subscribers should be divided into categories depending on
                      their activities and involvement. By dividing your
                      customers, you can engage with them effectively. Improve
                      your conversions or sales by focusing on a smaller portion
                      of their details, you can work effectively on their
                      specific needs.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="#/content/email-marketing"
                      >
                        Send a greetings email:
                      </a>
                    </h4>
                    <div className="text">
                      A greeting email is an opportunity to build a positive
                      impression on potential customers. It will help you to
                      properly educate your audience about your brand.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/content-writing"
                      >
                        Automate email campaigns:
                      </a>
                    </h4>
                    <div className="text">
                      Emails being sent out automatically depending on user
                      action are known as trigger-based emails. It helps you to
                      let your customer know you are actively connected with
                      them.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/reputation-management"
                      >
                        Send out email milestones:
                      </a>
                    </h4>
                    <div className="text">
                      Milestone emails support in the presence of long client
                      connections. This email generally denotes a certain point
                      in the client lifecycle. It may be a client's birthday or
                      anniversary.
                    </div>
                  </div>
                </div>

                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/conversion-optimization"
                      >
                        Give alert of new features or special offers:
                      </a>
                    </h4>
                    <div className="text">
                      Attract shoppers to get the most out of your product or
                      service. Tell a client about features they haven't tried
                      or aren't aware of.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/conversion-optimization"
                      >
                        Initiate contact at the correct time:
                      </a>
                    </h4>
                    <div className="text">
                      Customers value their time, so send your message when
                      they're willing to read it. Remember to check and respond
                      to every email that has been sent by the customer at
                      optimal timing.
                    </div>
                  </div>
                </div>
                {/* Service Block */}
                <div className="service-block col-lg-4 col-md-6 col-sm-12">
                  <div
                    className="inner-box wow fadeInLeft"
                    data-wow-delay="0ms"
                    data-wow-duration="1500ms"
                  >
                    <div className="border-layer" />

                    <h4>
                      <a
                        onClick={(e) => e.preventDefault()}
                        href="/content/conversion-optimization"
                      >
                        Implement re-engagement campaigns:
                      </a>
                    </h4>
                    <div className="text">
                      To keep your email list in fine condition, this is a
                      must-do activity. Your customers may lose interest in the
                      product, feel they no longer require your services, or
                      just forget about you. But just don't eliminate them from
                      your email list just yet. A re-engagement campaign can
                      help you regain their trust.
                    </div>
                  </div>
                </div>
              </div>
              <p style={styles.paraghrapText}>
                With Draw Topic you can start or update your email marketing
                strategy. If you integrate these new adjustments into your email
                marketing plan. We assure Customers will be more engaged,
                campaign performance will increase, and your business will
                continue to expand.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* End Benefit Section */}
    </div>
  );
}

export default EmailMarketing;

const styles = {
  title: {
    color: "#06092d",
    fonSize: "2.1rem",
    fontWeight: 800,
    textAlign: "center",
    padding: "0",
    marginTop: "40px",
    marginBottom: "30px",
  },
  paraghrapText: {
    color: "#222",
    fontSize: "1.2rem",
  },
};
