import React from "react";
import "./faq.css";

const Faq = () => {
  return (
    <>
      {/* <div class="accordion" id="accordionExample">
  <div class="card">
    <div class="card-header" id="headingThree">
      <h2 class="mb-0">
        <button class="btn btn-outline-primary w-100 w-100 collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
          Collapsible Group Item #3
        </button>
      </h2>
    </div>
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
      <div class="card-body">
        Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
      </div>
    </div>
  </div>
</div> */}

      <>
        {/* Faq Section */}
        <section className="faq-section">
          <div className="auto-container">
            <div className="row clearfix">
              {/* Column */}
              <div className="column col-lg-6 col-md-12 col-sm-12">
                <div className="title-box">
                  <h4>Primary FAQ</h4>
                </div>

                <div class="accordion" id="accordionExample">
                  <div class="card">
                    <div class="card-header" id="headingOne">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-outline-primary w-100 w-100"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          1. What are the best Digital Marketing Strategies
                          followed by Digital Marketing Agencies ?
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne"
                      class="collapse"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        Digital Marketing Strategies include a series of
                        activities that help you achieve your goals for various
                        channels like paid, earned and owned media. Depending on
                        your business goals, your strategies might vary. Digital
                        Marketing agencies build strategies based on seven core
                        capabilities, which are a strategic approach,
                        performance improvement process, management buy-in,
                        resourcing and structure, data and infrastructure,
                        integrated customer communications and customer
                        experience.
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingTwo">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-outline-primary w-100 w-100 collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          2. What are the services provided by Digital Marketing
                          Agencies ?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      class="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        Digital Marketing agencies provide a lot of services for
                        various online activities like Search Engine
                        Optimization (SEO), Content Marketing (Blogging &
                        Article Writing), Downloadable Content Offerings, Email
                        Marketing, Social Media Marketing, Pay-Per-Click (PPC)
                        Marketing, Video Production, Website Design / Micro-site
                        Development, Public Relations (PR).{" "}
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingThree">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-outline-primary w-100 w-100 collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          3. Why using Digital Marketing Services is essential /
                          important?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      class="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        Digital Marketing helps you build your online business
                        and generate sales and profits through different
                        channels and platforms. To increase the reach and
                        awareness of your business, Digital Marketing Services
                        are essential and highly recommendable since you get the
                        opportunity to reach out to your target and communicate
                        one-on-one with them.{" "}
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingEight">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-outline-primary w-100 w-100 collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseEight"
                          aria-expanded="false"
                          aria-controls="collapseEight"
                        >
                          4. Is digital branding the same as digital marketing?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseEight"
                      class="collapse"
                      aria-labelledby="headingEight"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        Digital branding is a subset of digital marketing. While
                        digital branding will only focus on creating an
                        awareness about your product/service/brand and giving
                        you a digital presence, digital marketing provides a
                        holistic view and helps you generate awareness,
                        sales/conversions and maintain a strong relationship
                        with your customers through Online Reputation
                        Management.{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Column */}
              <div
                style={{ marginTop: "30px " }}
                className="column col-lg-6 col-md-12 col-sm-12"
              >
                <div className="title-box">
                  {/* <h4>Other questions</h4> */}
                </div>

                <div class="accordion" id="accordionExample">
                  <div class="card">
                    <div class="card-header" id="headingFour">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-outline-primary w-100 w-100"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="true"
                          aria-controls="collapseFour"
                        >
                          5. What are the advantages of Digital Marketing
                          services?
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseFour"
                      class="collapse"
                      aria-labelledby="headingFour"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        Digital Marketing services help you increase brand
                        awareness, brand loyalty and drive sales for your
                        business. The main advantage of Digital Marketing is
                        that it helps you measure success, performance and
                        results of all the activities across all platforms in a
                        very cost-effective way.{" "}
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingFive">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-outline-primary w-100 w-100 collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          6. What are the top Digital Marketing tools used in a
                          Digital Marketing Agency?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseFive"
                      class="collapse"
                      aria-labelledby="headingFive"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        To perform Digital Marketing activities like SEO,
                        Analytics, Content Marketing, Social Media, Email
                        Marketing etc., Digital Marketing Agencies use a lot of
                        automation tools to ease their campaign-related work.
                        Google Analytics, SEM Rush, Google Adwords, Keyword
                        Planner, HootSuite and sproutSocial are some of the most
                        used tools.{" "}
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingSix">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-outline-primary w-100 w-100 collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseSix"
                          aria-expanded="false"
                          aria-controls="collapseSix"
                        >
                          7. What are the best Digital Marketing activities
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseSix"
                      class="collapse"
                      aria-labelledby="headingSix"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        Digital Marketing consists of a lot of activities which
                        can help different business types and needs, but the
                        best and most beneficial activities which every business
                        must use are SEO ( Search Engine Optimization), SEM (
                        Search Engine Marketing) or PPC, Social Media activities
                        and Email marketing. These activities will help create a
                        good online presence. These days a lot of importance is
                        given to Content Generation and Marketing. Though it is
                        a long-term process, but it helps create a strong brand
                        recall and perception.{" "}
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-header" id="headingSeven">
                      <h2 class="mb-0">
                        <button
                          class="btn btn-outline-primary w-100 w-100 collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseSeven"
                          aria-expanded="false"
                          aria-controls="collapseSeven"
                        >
                          8. What is brand awareness in digital marketing?{" "}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseSeven"
                      class="collapse"
                      aria-labelledby="headingSeven"
                      data-parent="#accordionExample"
                    >
                      <div class="card-body">
                        Digital Marketing services help you increase brand
                        awareness, brand loyalty and drive sales for your
                        business. The main advantage of Digital Marketing is
                        that it helps you measure success, performance and
                        results of all the activities across all platforms in a
                        very cost-effective way.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Faq Section */}
      </>
    </>
  );
};

export default Faq;
