import React from "react";
import pattern3 from "../../images/background/pattern-3.png";
import pattern4 from "../../images/background/pattern-4.png";
import pattern5 from "../../images/background/pattern-5.png";
import pattern6 from "../../images/background/pattern-6.png";
import benefit1 from "../../images/resource/benefit-1.jpg";
import benefit2 from "../../images/resource/benefit-2.png";
import benefit3 from "../../images/resource/benefit-3.jpg";
import cert from "../../images/resource/cert.png";
import cert1 from "../../images/resource/cert1.png";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
const newLOGO = "new-logo-underline.png";
const About = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* Benefit Section */}
      <section className="benefit-section">
        <div
          className="background-layer-one"
          style={{ backgroundImage: `url(${pattern5})` }}
        />
        <div
          className="background-layer-two"
          style={{ backgroundImage: `url(${pattern6})` }}
        />
        <div className="auto-container">
          <div className="row clearfix">
            {/* Images Column */}
            <div className="images-column col-lg-7 col-md-12 col-sm-12">
              <div className="inner-column">
                <div
                  className="pattern-layer"
                  style={{ backgroundImage: `url(${pattern4})` }}
                />
                <div
                  className="pattern-layer-two"
                  style={{ backgroundImage: `url(${pattern3})` }}
                />
                <div className="color-layer" />
                <div className="image">
                  <img
                    src={
                      "https://image.freepik.com/free-psd/laptop-iphone-digital-marketing-background_23-2148346689.jpg"
                    }
                    alt
                  />
                </div>
                <div className="image-two">
                  <img
                    width="354px"
                    height="337px"
                    src={
                      "https://media-assets-02.thedrum.com/cache/images/thedrum-prod/s3-news-tmp-213062-hallam_digitalmarketing1--default--1280.jpg"
                    }
                    alt
                  />
                </div>
                <div className="image-three">
                  <img width="354px" height="337px" src={benefit2} alt />
                </div>
              </div>
            </div>
            {/* Content Column */}
            <div className="content-column col-lg-5 col-md-12 col-sm-12">
              <div className="inner-column">
                <div className="sec-title">
                  {/* <div className="title">Learn anything</div> */}
                  <h2 style={{ marginLeft: "70px" }}>ABOUT US</h2>
                </div>
                <ul className="list-style-one">
                  {/* <li><span className="icon flaticon-double-check" /><h3> {t('about')}: </h3> <strong style={{color:"#9e7d18"}}> TM7 SERVICES INC</strong><p>{t('TM7_description')}</p></li>
            <div className="btn-box">
            <a href="https://www.tm7.tech/"
                        target="_blank" className="theme-btn btn-style-two"><span className="txt">{t('find_out_button')} TM7 SERVICES INC</span></a>
          </div> */}
                  <li>
                    <strong style={{ color: "#9e7d18" }}>
                      Draw Topic Digital
                    </strong>
                    <p>
                      Draw Topic Digital is dedicated to learning about and
                      understanding your business. By building a relationship
                      with each of our clients, we can ensure our marketing
                      strategy is built with the sole focus of solving the
                      problems your business faces. Each of our marketing
                      campaigns is built with the client's needs in mind to
                      solve the greatest marketing problems
                    </p>
                  </li>
                  <div className="btn-box">
                    <a
                      href="#contact"
                      // target="_blank"
                      className="theme-btn btn-style-two"
                    >
                      <span className="txt">
                        {t("find_out_button")} Draw Topic
                      </span>
                    </a>
                  </div>
                </ul>
              </div>
            </div>
          </div>
          {/* Lower Text */}
          <div className="lower-text">
            <p>
              READY TO GROW YOUR BUSINESS? <br />
              Contact us to work with a results-driven digital marketing agency
            </p>
          </div>
        </div>
        <div className="auto-container">
          <div className="row clearfix">
            {/* Images Column */}
            <div className="images-column mt-5 col-lg-6 col-md-12 col-sm-12">
              <img src={cert} />
            </div>
            <div className="images-column mt-5 ml-auto col-lg-6 col-md-12 col-sm-12">
              <img src={cert1} />
            </div>
          </div>
        </div>
      </section>
      {/* End Benefit Section */}
    </>
  );
};

export default About;
