import { Route } from "react-router";
import Footer from "../components/footer/Footer";
import TrueHeader from "../components/TrueHeader";
import ContentWriting from "../content/content_writing/ContentWriting";
import ConversationOptimization from "../content/conversation_optimization/ConversationOptimization";
import EmailMarketing from "../content/email_marketing/EmailMarketing";
import LocalSEO from "../content/local_seo/LocalSEO";
import PayPerClick from "../content/pay_per_click/PayPerClick";
import ReputationManagement from "../content/reputation_management/ReputationManagement";
import SEO from "../content/seo/SEO";
import ASO from "../content/aso/ASO";
import ContentMarketing from "../content/content_marketing/ContentMarketing";
import SocialMedia from "../content/social_media/SocialMedia";
import VideoProduction from "../content/video_production/VideoProduction";
import Header from "../components/header/Header";
import { useLocation } from "react-router-dom";
function NormalRoute() {
  const { pathname } = useLocation();

  return (
    <>
      <Route path="/" exact>
        <Header />
      </Route>
      {pathname === "/" ? null : <TrueHeader />}

      <Route path="/content/search-engine-optimization">
        <SEO />
      </Route>
      <Route path="/content/ASO">
        <ASO />
      </Route>
      <Route path="/content/social-media">
        <SocialMedia />
      </Route>
      <Route path="/content/pay-per-click">
        <PayPerClick />
      </Route>
      <Route path="/content/video-production">
        <VideoProduction />
      </Route>
      <Route path="/content/content-writing">
        <ContentWriting />
      </Route>
      <Route path="/content/content-marketing">
        <ContentMarketing />
      </Route>
      <Route path="/content/reputation-management">
        <ReputationManagement />
      </Route>
      <Route path="/content/email-marketing">
        <EmailMarketing />
      </Route>
      <Footer />
    </>
  );
}

export default NormalRoute;
